import { Area, CountPriceCategory, Currency, PricingModel } from '../pricing';
import { CustomerInfo } from '..';
import { PaymentStyle } from '../payment';
import { Service, ServiceSubType } from '../services';
import { UserId } from '@mindhiveoy/schema';

export type OrderId = string;
export type UTCTime = number;
export type PaymentId = string;

export interface OrderRow {
  /**
   * Unique order row id. The id is generarated unique id created once when row is created.
   */
  rowId?: string;

  /**
   * Service id of the ordered service
   */
  serviceId: string;

  /**
   * Service id of the parent service if this one is an extra service
   */
  partOfService?: string;

  /**
   * Service that was ordered, with all information about the service
   * It is useful to store a copy of the whole service info so that users are able to see the detailed order history
   * even if the service was deleted/modified
   */
  service: Service;

  /**
  * How long will the order supposably take in minutes
  */
  estimatedTime: number;

  /**
   * Selected pricing model
   */
  pricingModel: PricingModel;

  /**
   * Price of the service before taxes
   */
  totalPriceBeforeTaxes: Currency;

  /**
   * Total price of the order row
   */
  totalPrice: Currency;

  /**
   * Total VAT of the order row
   */
  totalVat: Currency;

  /**
   * Date booked for the service
   */
  bookedDate: string;

  /**
   * Preferred time manually specified by the user
   */
  preferredTime?: string;

  /**
  * Selected sub type for eligible orders (e.g. Tehosiivous etc.)
  */
  subType?: ServiceSubType;

  /**
   * Parameters that are important to the service provider, manually entered by the user (e.g. apartment area)
   */
  userSpecifiedParameters?: UserSpecifiedParameters;

  /**
   * Particular selected price category, needed for renovation services
   */
  selectedCountPriceCategory?: CountPriceCategory;
}

/**
 *  An order related payment object
 */
export interface PaymentInfo {

  /**
   *  paymant style (for example Visma Pay)
   */
  paymentStyle : PaymentStyle;

  /**
   *  payment status
   */
  paymentStatus : PaymentStatus;

  /**
   *  payment Id to identify payment in payment system
   */
  paymentId : PaymentId;
}

/**
 * An order defining a single order of a specific service
 */
export interface Order extends PaymentInfo {
  /**
   * Order id in database
   */
  id?: OrderId;

  /**
   * Order rows listing all services being ordered inside this order (including extras)
   */
  rows: OrderRow[];

  /**
   * Price of the service before taxes
   */
  totalPriceBeforeTaxes: Currency;

  /**
  * Total price of the order, including extra services
  */
  totalPrice: Currency;

  /**
  * Total VAT of the order, including extra services
  */
  totalVat: Currency;

  /**
   * Date when order was created in UTC time. The timestamp must always be set by the server timestamp.
   */
  creationDate: UTCTime;

  /**
   * Customer information including user's name, phone, address
   */
  customerInfo: CustomerInfo;

  /**
   * User id of the customer
   */
  uid: UserId;

  /**
   * Order status
   * @see OrderStatus
   */
  status: OrderStatus;
}

export enum OrderStatus {
  /**
   * Processing order
   * Order is in processing mode if order is not paid
   */
  PROCESSING = 1,
  /**
   * Payment pending
   */
  NOT_CONFIRMED = 10,
  /**
   * Payment succeeded
   */
  CONFIRMED = 20,
  /**
   * Order is done
   */
  READY = 50,
  /**
   * Order was cancelled by the user
   */
  CANCELLED =60,
  /**
   * Error state
   */
  ERROR = 99,
}

/**
 * Digest of Order's data to be denormalized to other documents.
 */
export type OrderDigest = Pick<Order,
 'creationDate' |
 'customerInfo' |
 'id' |
 'rows' |
 'status' |
 'totalPrice' |
 'totalPriceBeforeTaxes' |
 'totalVat' |
 'uid'
>;

/**
 * Generic type for all types of user specified parameters
 */
export type UserSpecifiedParameters = ApartmentParameters | PriceCategoryParameters |
  ApartmentPriceCategoryParameters | LaundryFormParameters | MovingFormParameters;

/**
 * Possible types of user specified parameters
 */
export type UserSpecifiedParametersType = 'apartment' | 'priceCategoryId' | 'priceCategoryIdAndApartment' | 'filledLaundryForm' | 'filledMovingForm';

/**
 * Base type for user specified parameters
 */
export interface UserSpecifiedParametersBase {
  type: UserSpecifiedParametersType;
}

/**
 * Parameters for the apartment for the services where this is important
 */
export interface ApartmentParameters extends UserSpecifiedParametersBase {
  type: 'apartment',
  /**
   * Area of the apartment
   */
  apartmentArea: Area;
}

export interface PriceCategoryParameters extends UserSpecifiedParametersBase {
  type: 'priceCategoryId',
  priceCategoryId: string;
}

export interface ApartmentPriceCategoryParameters extends UserSpecifiedParametersBase {
  type: 'priceCategoryIdAndApartment',
  /**
   * Area of the apartment
   */
  apartmentArea: Area;

  priceCategoryId: string;
}

export interface FilledCountPriceCategory extends CountPriceCategory {
  count: number;
  totalPriceBeforeTaxes: number;
}

export interface LaundryFormParameters extends UserSpecifiedParametersBase {
  type: 'filledLaundryForm',
  selectedCountPriceCategories: FilledCountPriceCategory[],
}

export interface MovingFormParameters extends UserSpecifiedParametersBase {
  type: 'filledMovingForm',
  selectedCountPriceCategories: FilledCountPriceCategory[],
  apartmentArea: Area,
}

export enum PaymentStatus {
  /**
   *  payment is in progress
   */
  IN_PROGRESS = 10,
  /**
   *  payment is completed
   */
  PAID = 20,
  /**
   *  payment is failed
   */
  ERROR = 30

}
