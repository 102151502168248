import { Order } from '../orders';

export * from './common';
export * from './chargeObject';
export * from './checkReturn';
export * from './paymentMethod';
export * from './statusObjects';

/**
 *  Visma Pay submerchant required info
 */
export interface VismaPayMerchant {

  /**
   *  internal related service Id
   */
  serviceId : string;

  /**
   *  merchant ID in Visma Py portal
   */
  merchantId : number;

  /**
   *  private key of a merchant
   */
  privateKey : string;
  /**
   * API key of a merchant
   */
  apiKey : string;

  /**
   *
   */
  cp : string;
}

/**
 * Response interface base for VismaPay payment
 */
interface VismaPaymentCheckResponseBase {
  status: VismaPaymentCheckStatus.IN_PROGRESS | VismaPaymentCheckStatus.SUCCESS;
}

interface VismaPaymentResponseFailed {
  status: VismaPaymentCheckStatus.FAILED;

  /**
   * Error message is required when payment is failed
   */
  message: string;

  /**
   * If there is error on order payment, we should send order back to client,
   * so client can make new payment with same order data.
   */
  order?: Order;
}

export type VismaPaymentCheckResponse = VismaPaymentCheckResponseBase | VismaPaymentResponseFailed

export enum VismaPaymentCheckStatus {
  IN_PROGRESS = 'in_progress',
  SUCCESS = 'success',
  FAILED = 'failed'
}
