/**
 * # WEB DEVELOPMENT
 *
 * @copyright Mindhive Oy 2021-2022
 */
import { AppConfig } from '@shared/schema';

const config: AppConfig = {
  environment: {
    target: 'production',
    build: '$BUILD_NUMBER',
    version: '$VERSION_NUMBER',
    sha1: '',
    branch: '',
    tag: '',
    webHostAddress: 'https://block.fi',
  },
  firebase: {
    apiKey: 'AIzaSyAyFYBc-Spxn4YiSWysxytUtHCXJvpjyME',
    authDomain: 'block-81ff4.firebaseapp.com',
    projectId: 'block-81ff4',
    storageBucket: 'block-81ff4.appspot.com',
    messagingSenderId: '829866786179',
    appId: '1:829866786179:web:dac80c815a28e9470c4ab6',
    measurementId: 'G-2B1XKFDPDF',
  },
  google: {
    credentials: {
      android: '',
      ios: '',
    },
    apiKeys: {
      map: '',
      distanceMatrix: '',
    },
  },
  facebook: {},
  sanity: {
    projectId: '6nwoucjh',
    dataset: 'production',
    useCdn: false,
    apiVersion: '2021-08-31',
  },
  sentry: {
    dsn: 'https://54a052d434bf4d5587d5eec3d329308f@o426384.ingest.sentry.io/6367368',
  },
  messaging: {
    vapidKey: 'TODO',
  },

};

export default config;
