import { SendEmailVerificationLinkRequest } from '@shared/schema/emailVerification';
import { TypedHttpsCallable } from 'utils/https';
import { firebaseApp } from 'schema';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useAuth } from '@mindhiveoy/react-auth';
import { useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const functions = getFunctions(firebaseApp());

const Step4 = () => {
  const {
    t,
  } = useTranslation();

  const {
    user,
  } = useAuth();

  const handleResendConfirmationClick = useCallback(async () => {
    const sendVerifyEmailLink = httpsCallable(functions, 'sendVerifyEmailLink') as TypedHttpsCallable<SendEmailVerificationLinkRequest, undefined>;
    await sendVerifyEmailLink({
      email: user?.email as string,
      spaceId: '',
    });
  },[user?.email,]);

  return <>
    <Grid container rowGap={2} alignItems='center'>

      <Grid item xs={12} sm={8}>
        <Typography variant='h1'>
          {t('Thank you for your registration')}!
        </Typography>
      </Grid>

      <Grid item xs={12} sm={8}>
        <Typography variant='body1'>
          {t('We have sent a confirmation email to the address you provided')}.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Typography variant='body1'>
          {t('You can order services after confirming your email address')}.
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12}>
        <Typography variant='body1'>
          {t('Didn\'t get an email')}?
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Button variant='text' color='primary' onClick={handleResendConfirmationClick} >
          <span className='button-text-underline'>{ t('Resend confirmation email') }</span>
        </Button>
      </Grid>
    </Grid>
  </>;
};
export default Step4;
