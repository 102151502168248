import { StepProps } from '../RegistrationProgressFlow';
import { useTranslation } from 'next-i18next';
import Button from '@mui/material/Button';
import FormElement from 'components/forms/FormElement';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import theme from 'theme';
import useMediaQuery from '@mui/material/useMediaQuery';

const Step1 = ({
  nextOnClick,
  cancelOnClick,
  form,
  events,
  messages,
}: StepProps) => {
  const {
    t,
  } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return <>
    <Grid container rowGap={2} alignItems='center'>

      <Grid item xs={12} sm={8}>
        <Typography variant='h1'>
          {t('Register')}
        </Typography>
      </Grid>

      {/* Help text */}
      <Grid item xs={12} sm={8}>
        <Typography variant='body1'>
          {t('Please give your email address Make sure the address is correct and press Next')}.
        </Typography>
      </Grid>

      <Grid container rowGap={1} marginTop={isMobile ? 3 : 7} columnGap={2} marginBottom={7}>
        <Grid item xs={12}>
          <Typography variant='body2'>
            {t('Email address')}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={5}>
          <FormElement field="email">
            <TextField
              value={form.values.email}
              id="email"
              {...events}
            />
          </FormElement>
        </Grid>

        <Grid item xs={12} sm={4} marginTop={isMobile ? 2 : 0}>
          <Button variant='contained' color='secondary' onClick={nextOnClick(1)} disabled={!!messages.email || !form.values.email.length}>
            {t('Next')}
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12}>
        <Button variant='text' color='primary' onClick={cancelOnClick} >
          <span className='button-text-underline'>{t('Cancel')}</span>
        </Button>
      </Grid>
    </Grid>
  </>;
};
export default Step1;
