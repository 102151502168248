/* eslint-disable sonarjs/no-duplicate-string */

import { Theme as MaterialTheme, PaletteOptions, ThemeOptions, createTheme } from '@mui/material/styles';
import { Shadows } from '@mui/material/styles/shadows';
import { TypeBackground, TypeText } from '@mui/material/styles/createPalette';

type BlockTheme = MaterialTheme & BlockThemeExtensions;

interface BlockPalette extends PaletteOptions {
  background: Partial<TypeBackground> & {
    /**
     * The background color of papers on the top of a background paper
     */
  };
  text: Partial<TypeText> & {
    lightgrey: string;
  }
}
interface BlockThemeExtensions {
  animations: {
    hovers: {
      /**
       * Hover on card defining some basic concept on the UI like
       * project, query or question.
       */
      card?: string;
    }
  },
  palette: BlockPalette;
  constants: {
    headerHeight: string;
    bottomHeight: string
  }
}
/**
 * Cast Emotion's Theme type to Material theme
 */
declare module '@emotion/react' {
  export interface Theme extends BlockTheme {
    custom: string; // Needed to make
  }
}

type BlockOptions = ThemeOptions & BlockThemeExtensions;

const ITEM_HOVER_STYLE = 'transform: scale(1.03);' +
  'box-shadow: 0px 10px 13px -7px #000000, 11px 15px 15px 3px rgba(0,0,0,0);';

const {
  palette,
} = createTheme();
let theme = createTheme();

const themeOptions: BlockOptions = {
  spacing: 10,
  animations: {
    hovers: {
      card: ITEM_HOVER_STYLE,
    },
  },
  shape: {
    borderRadius: 25,
  },
  shadows: Array(25).fill('none') as Shadows,
  constants: {
    headerHeight: theme.breakpoints.down('sm') ? '100px' : '122px',
    bottomHeight: '72px',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html {
          -webkit-font-smoothing: auto;
          margin: 0;
        }
        body {
          margin: 0;
        }
        `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          'borderRadius': 25,
          'padding': '14px 20px',
          'boxShadow': 'none',
          'textTransform': 'none',
          'whiteSpace': 'nowrap',
          [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
          '&.Mui-disabled': {
            'backgroundColor': '#D6D6D6',
            'color': '#6A6A6A',
          },
        },
        text: {
          'color': '#1e1e1e',
          'textAlign': 'left',
          'padding': '14px 20px 14px 20px',
          ':hover': {
            'backgroundColor': 'transparent',
            '.button-text-underline': {
              color: '#E5361F',
              borderBottom: '1px solid #E5361F',
            },
          },
        },
        contained: {
          ':hover:not(.button-contained-lightgrey)': {
            'backgroundColor': '#C61C1C',
            'boxShadow': 'none',
          },
          '&.button-contained-lightgrey': {
            ':hover': {
              'backgroundColor': '#F5F5F5 !important',
              'color': '#C61C1C !important',
              'boxShadow': 'none !important',
            },

          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          'width': '100%',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '.MuiOutlinedInput-notchedOutline': {
            'borderColor': '#6a6a6a',
          },
        },
        input: {
          'border': '1px solid 1e1e1e',
          'borderRadius': '25px',
          'color': '#1e1e1e',
          'padding': '1rem',
          /* input autofill color */
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 200px #fff inset',
            WebkitTextFillColor: '#000',
          },
        },

      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          'height': '46px',
          'width': '46px',
          'backgroundColor': '#F5F5F5',
          'padding': 0,
          'objectFit': 'contain',
          '& > .MuiSvgIcon-root': {
            width: '1.2em',
            height: '1.2em',
          },
          ':hover': {
            'color': '#C61C1C',
            'backgroundColor': '#F5F5F5',
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {},
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          'color': '#6a6a6a',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          maxWidth: '16rem',
          borderRadius: 0,
          boxShadow: 'none',
          position: 'relative',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          padding: 0,
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          padding: '0 0 0.188rem 0',
          display: 'flex',
          justifyContent: 'space-between',
          gap: '0.5rem',
          cursor: 'pointer',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#EBEBEB',
          margin: '1rem 0',
        },
      },
    },
    MuiBadge: {},
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: '#FAFAFA !important',
          borderRadius: '12px !important',
        },
        list: {
          padding: 0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          'padding': '1rem',
          'backgroundColor': '#FAFAFA',
          ':hover': {
            'backgroundColor': '#F8F8F8',
          },
          '&.Mui-selected': {
            'color': '#6A6A6A',
          },
        },
      },
    },
  },
  typography: {
    fontFamily: [
      'Gilroy',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Helvetica Neue"',
      'sans-serif',
    ].join(','),
    allVariants: {
      color: '#1e1e1e',
      margin: '0 !important',
    },
    h1: {
      fontSize: '1.5rem',
      fontWeight: 600,
      letterSpacing: '0.12px',
      lineHeight: '1.875rem',
    },
    h2: {
      fontSize: '1.125rem',
      fontWeight: 300,
      letterSpacing: '0.09px',
      lineHeight: '1.5rem',
    },
    h4: {
      fontSize: '0.9375rem',
      fontWeight: 300,
      textTransform: 'uppercase',
      color: '#E5361F',
    },
    h5: {
      'fontSize': '1.25rem',
      'fontWeight': 500,
      'lineHeight': '2rem',
      '&.h5-underlined-red': {
        borderBottom: '1px solid #E5361F',
      },
    },
    h6: {
      'fontSize': '1.125rem',
      'lineHeight': '1.625rem',
      'letterSpacing': '0.006rem',
      'fontWeight': 400,
      '&.price': {
        fontWeight: 400,
      },
      '&.discount-price': {
        fontWeight: 400,
        color: '#E5361F',
      },
      '&.selected-text': {
        color: '#fff',
      },
      '&.cardActions-text': {
        fontSize: '1.01rem',
      },
    },
    body1: {
      'fontSize': '0.938rem',
      'marginTop': '0.75rem',
      'marginBottom': '1rem',
      'fontWeight': 400,
      'letterSpacing': '0.07px',
      'lineHeight': '1.313rem',
      'display': 'flex',
      'alignItems': 'center',
      'borderBottom': '1px solid transparent',
      ':hover': {
        '&.body-underlined-red': {
          borderBottom: '1px solid #E5361F',
        },
      },
      '&.selected-text': {
        color: '#fff',
      },
    },
    body2: {
      'fontSize': '0.938rem',
      'marginTop': '0.75rem',
      'marginBottom': '1rem',
      'fontWeight': 600,
      'letterSpacing': '0.07px',
      'lineHeight': '1.313rem',
      '&.body2-underlined-red': {
        borderBottom: '1px solid #E5361F',
      },
      '&.body2-underlined-dark': {
        borderBottom: '1px solid #1e1e1e',
      },
    },
    /**
     * E.g service card subtitle
     */
    subtitle1: {
      fontSize: '0.813rem',
      lineHeight: '1.313rem',
      letterSpacing: '0.004rem',
      color: '#6A6A6A',
      paddingBottom: '0.875rem',
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#1e1e1e',
      contrastText: '#fff',
    },
    secondary: {
      main: '#E5361F',
    },
    info: {
      main: '#1E1E1E',
    },
    action: {
      disabledBackground: '#C2C2C2',
      disabled: '#ffffff80',
      selected: '#85C71A',
      active: '#FCC615',
    },
    error: {
      main: '#E94926',
    },
    background: {
      default: '#FCFBFA',
      paper: 'rgba(50,50,50,0.85)',
    },
    text: {
      primary: '#1E1E1E',
      secondary: '#E5E5E5',
      lightgrey: '#D3D3D3',
    },
    lightgrey: palette.augmentColor({
      color: {
        main: '#F5F5F5',
      },
    }),
    midgrey: palette.augmentColor({
      color: {
        main: '#D6D6D6',
      },
    }),
  },
};

// Create a theme instance.
theme = createTheme(themeOptions) as BlockTheme;

export default theme;
