/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChargeResultCode, EPaymentTypes, PaymenObjectLanguage, VismaPayPaymentMethod } from './common';
import { Currency } from '../index';
import { Email, URL } from '@mindhiveoy/schema';

/**
 * Visma Pay: Charge Object
 * https://www.vismapay.com/docs/web_payments/?page=reference-node#create-charge
 */
export interface ChargeObject {
  /**
   * The order number shall be unique for every payment and contain only numbers and letters (a-z).
   */
  order_number: string;

  /**
   * Amount is in fractional monetary units e.g. 1€ = 100. Minimum value accepted is 1.
   * If products are given amount must match with products' total prices.
   */
  amount: Currency;

  /**
   * See currencies for more information.
   * https://www.vismapay.com/docs/web_payments/?page=full-api-reference#currencies
   */
  currency: string;

  /**
   * If this is set the payment system will automatically send payment receipt to the given email address.
   */
  email?: Email;

  /**
   * The payment method object.
   */
  payment_method: PaymentObject;

  /**
   * The customer object.
   */
  customer?: any; // TODO: Customer interface

  /**
   * Array containing charge product objects.
   */
  products?: any[]; // TODO: Product interface
}

/**
 * Visma Pay: Payment Object
 * https://www.vismapay.com/docs/web_payments/?page=reference-node#create-charge
 */
export interface PaymentObject {
  /**
  * The type of the payment method. Possible values are e-payment, embedded and terminal.
  */
  type: VismaPayPaymentMethod;

  /**
   * Boolean integer defining if a card specific token should be registered.
   * A card token is returned in the payment status check if a payment has been successful. 0 = do not register, 1 = register.
   * If e-payment-type is used, it might be good idea to limit visible payment methods to only credit cards using the selected-parameter.
   * Card token registration is not supported for terminal payments
   */
  register_card_token?: number;

  /**
   * A customer is redirected to this page after a payment.
   * GET parameters can be added to the url as long as they do not confict with the ones that are returned.
   */
  return_url: URL;

  /**
   * This URL is called after a payment has been marked to a final state (success or failed).
   * The URL is called with the same GET parameters as return_url.
   * The request is done by Visma Pay server so a customer browser session doesn't exist.
   * The request is normally done after couple of minutes if a payment was processed properly but it can take up to 30 minutes
   * if a payment status has to be checked from a payment processor's interface.
   * The interface is expecting a HTTP status code 200 if the query is caught.
   * The notify request is retried up to 3 times. Only URLs with protocol default ports (https:443, http:80) are supported.
   * In some rare cases it's possible that the status of the transaction changes
   * from failed to successful later. In such cases the notify request is done again.
   */
  notify_url: URL;

  /**
   * Language used in during the payment process, allowed values are fi (default), en, sv or ru
   */
  lang?: PaymenObjectLanguage;

  /**
   * Determines how long the payment link is active and can be reused to pay the order.
   * Payment link deactivates after the payment changes to final state.
   * Allowed values are 1 hour from current timestamp to 7 days from current timestamp.
   * Default value is 1 hour from current timestamp.
   */
  token_valid_until?: number;

  /**
   * Check description from
   * https://www.vismapay.com/docs/web_payments/?page=reference-node#create-charge
   */
  override_auto_settlement?: number;

  /**
   * Additional fields for e-payment type
   * Optionally preselected payment methods which will be available for customer.
   * You can limit the set of available payment methods to allow usage of only defined banks, credit invoice systems or card payments.
   * If only one specific payment method is selected, the customer will be redirected automatically to the payment providers payment page.
   * Exceptions to this are banks and creditinvoices, which are shorthands for displaying payment methods belonging to that category.
   * Make sure that all the selected payment methods support the currency you are using, otherwise the api will return a validation error.
   * See currencies for which currencies are supported by different payment methods.
   */
  selected?: EPaymentTypes[];

  /**
   * Optionally preselected terminals which will be available for payment.
   * You can limit the set of available terminals to allow usage of only defined terminals.
   * If only one specific terminal is selected, there will be an automatic redirection to the Point-of-Sale page which connects to that terminal.
   */
  selected_terminal?: any[];

  /**
   * Optional boolean integer defining if the receipt-page should be skipped after a succesful payment.
   * 0 = do not skip (default), 1 = skip.
   * If the receipt-page is skipped, the receipts need to be printed from the merchant's own system.
   */
  skip_receipt?: number;
}

/**
 * Product's type. Value 1 indicates normal product row. Value 2 indicates shipment costs. Value 3 indicates handling costs. Value 4 indicates discount.
  */
export enum ChargeProductType {
  NORMAL = 1,
  SHIPMWNT_COST = 2,
  HANDLING_COST = 3,
  DISCOUNT = 3
}

export interface ChargeProduct {
  /**
   * Product's id provided by the merchant in the payment token request.
   */
  id?: string;

  /**
   * Integer Product's id in Visma Pay's system. Can be used for refunding by using our API.
   */
  product_id?: number,

  /**
   * Product's name.
   */
  title: string;

  /**
   * Amount of products
   */
  count: number;

  /**
   * Product's tax percent. Returned as integer without %-sign e.g 24% = 24
   */
  tax: number;

  /**
   * Product's pretax price. Can also be negative if type is 4 (discount).
   */
  pretax_price: number;

  /**
   * Product's price. Can also be negative if type is 4 (discount).
   */
  price: number;

  /**
   * Product's type.
   */
  type: ChargeProductType;

  /**
   * ID of the merchant's sub merchant id that is attached to the channel. Only returned for Channel payments
   */
  merchant_id?: number;

  /**
   *  Provision level ID for the product. See merchant portal for a list of all the provisions.
   */
  cp?: string;
}

export interface PaymentCustomer {
  /**
   * Customer's first name.
   */
  firstname?: string;

  /**
   * Customer's last name
   */
  lastname?: string;

  /**
   * Email Customer's email address.
   */
  email?: string;

  /**
   * Customer's street address.
   */
  address_street?: string;

  /**
   * Customer's city address.
   */
  address_city?: string;

  /**
   * Customer's zip code.
   */
  address_zip?: string;

  address_country?: string;

 }

export interface ResultObject {
  result: ChargeResultCode;

  /**
   * A payment identification token that will be used in a charge request.
    * This value is only returned if the result field was 0 (success).
   */
  token?: string;

  /**
   * The type of the token. Can be e-payment, embedded or terminal.
   * This value is only returned if the result field was 0 (success).
   */
  type?: VismaPayPaymentMethod;

  /**
   * Array containing explanations of validation errors.
   * Only returned if there have been specific validation errors.
   */
  errors?: any[]; // TODO: Interface for this
}
