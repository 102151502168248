/* eslint-disable sonarjs/no-small-switch */
import {
  CategoryPricingModel, CodePriceCategory, OrderRow, PriceCategory, PriceCategoryParameters
} from '..';

export const getActualOrderItemName = (row: OrderRow): string => {
  switch (row.service.type) {
    case 'repair':
      return row.selectedCountPriceCategory?.name ?? 'Service';
    case 'interiorDesign':
    case 'grooming':
      const selectedId = (row.userSpecifiedParameters as PriceCategoryParameters).priceCategoryId ?? '';
      const selectedCategory = (row.pricingModel as CategoryPricingModel<PriceCategory>).categories.find((category) => category.id === selectedId);
      const selectedType = selectedCategory?.type ?? '';
      const selectedName = selectedCategory?.name ?? '';
      const selectedOption = row.service.type === 'interiorDesign' ? getInteriorDesignName(selectedType ?? '') : getGroomingName(selectedType ?? '');
      return `${selectedOption}${selectedName}`;
    case 'law':
      const selectedLawId = (row.userSpecifiedParameters as PriceCategoryParameters).priceCategoryId ?? '';
      const selectedLawCategory = (row.pricingModel as CategoryPricingModel<CodePriceCategory>).categories.find((category) => category.id === selectedLawId);
      return `${selectedLawCategory?.name ?? ''}`;
    default:
      return row.service.name;
  }
};

export const getMovingQuestion = (categoryType: string) => {
  switch (categoryType) {
    case 'oldFloor':
      return 'Nykyinen asuinkerros';
    case 'newFloor':
      return 'Uusi asuinkerros';
    case 'oldElevator':
      return 'Nykyisessä talossa hissi';
    case 'newElevator':
      return 'Uudessa talossa hissi';
    case 'travelDistance':
      return 'Ajomatka';
    case 'parkingDistance':
      return 'Pysäköinti';
    case 'goodsAmountEstimate':
      return 'Tavaran määrä';
    case 'needToMoveStorage':
      return 'Varaston/kellarin muutto';
    case 'movingArea':
      return 'Muuttopalvelu';
    case 'numberOfTenants':
      return 'Asukkaiden määrä';
    default:
      return '';
  }
};

export const getInteriorDesignName = (categoryType: string) => {
  switch (categoryType) {
    case 'needs':
      return '';
    case 'space':
      return 'Toiminnallinen tilasuunnittelu: ';
    case 'surface':
      return 'Pintamateriaalien suunnittelu: ';
    case 'interior':
      return 'Sisustussuunnittelu (huone): ';
    default:
      return '';
  }
};

export const getGroomingName = (categoryType: string) => {
  switch (categoryType) {
    case 'small':
      return 'Pienet koirat: ';
    case 'average':
      return 'Keskikokoiset koirat: ';
    case 'big':
      return 'Isot koirat: ';
    default:
      return '';
  }
};

export const getOrderCode = (row: OrderRow) => {
  if (row?.service?.type === 'law' && row?.userSpecifiedParameters?.type === 'priceCategoryId') {
    const priceCategory = (row?.pricingModel as CategoryPricingModel<CodePriceCategory>)?.categories?.
      find((category) => category.id === (row?.userSpecifiedParameters as PriceCategoryParameters)?.priceCategoryId);
    return priceCategory?.code;
  }
  return '';
};
