/**
 *  interfaces of objects used in Visma Pay available payment methods request
 *  according
 *  https://www.vismapay.com/docs/web_payments/?page=reference-payment-methods#payment-methods-request
 */

import { EPaymentTypes } from './common';

/**
 *  interface of request object
 */
export interface PaymentMethodRequest {
  /**
   * Version number. Should be 2.
   */
  version: string;
  /**
   * The sub-merchant api key which is associated with the used private key.
   * The sub-merchant api key is listed in the sub-merchants page in the merchant UI.
   */
  api_key: string;
  /**
   * If a currency is given, only payment methods that support the currency are returned in the response.
   * Currencies use ISO 4217 format.
   */
  currency?: string;
  /**
   * MAC code for the request. It is calculated using HMAC-SHA256.
   * HMAC implementations exist for most popular programming languages.
   * Sub-merchant private key is used as the secret key and the message is calculated from the values of the following fields:
   * - api_key
   */
  authcode: string;
}

/**
 * Method object
 */
export interface PaymentMethod {
  /**
   * Payment method's name.
   */
  name: string;
  /**
   * Selected-value for the Payment API.
   */
  selected_value: EPaymentTypes;
  /**
   * Group which the payment method belongs to. Group can also be used as a selected-value in the Payment API.
   * New payment method groups might be added later.
   * Value Explanation
   * - bank Bank payment methods
   * - creditcards Card payment methods
   * - creditinvoices Credit invoice payment methods
   * - wallets Wallet payment methods
   */
  group: EPaymentTypes;
  /**
   * Minimum amount in fractional monetary units that can be paid using this payment method.
   * If currency field was set, then the amount will be returned in that currency.
   * If no currency was specified the amount will be returned in EUR cents.
   */
  min_amount : number;
  /**
   * Maximum amount in fractional monetary units that can be paid using this payment method.
   * If currency field was set, then the amount will be returned in that currency.
   * If no currency was specified the amount will be returned in EUR cents.
   */
  max_amount: number;
  /**
   * URL where the logo for the specific payment method can be found. The logos should be used with white (#fff) backgound.
   * (125x85px PNG-images)
   */
  img: string;
  /**
   * File timestamp for the payment method logo.
   */
  img_timestamp: string;
  /**
   * Array of supported currencies by the payment method. Currencies use ISO 4217 format.
   */
  currency: string[];
}

/**
 * Payment methods request returns
 * https://www.vismapay.com/docs/web_payments/?page=reference-payment-methods#payment-methods-return
 */
export enum EPaymentMethodsRequestResultCode {
  'SUCCESS' = 0,
  'FAILED' = 1,
  'MAINTENANCE_BREAK' = 10,
}

/**
 * Payment Methods return fields
 */
export interface PaymentMethodReturn {
  /**
   * result code
   */
  result: EPaymentMethodsRequestResultCode;
  /**
   * Array of Method objects.
   * This value is only returned if result field was 0 (success).
   */
   payment_methods?: PaymentMethod[];
}
