/**
 * The type of the charge
 */
export enum PaymentChargeType {
  CARD = 'card',
  TERMINAL = 'terminal',
  E_PAYMENT = 'e-payment'
}

/**
 * source object representation according Visma Pay API
 * https://www.vismapay.com/docs/web_payments/?page=full-api-reference#source-object
 * limited set of field are defined now
 *
 */
export interface SourceObject
{
  /**
   * The type of the charge, card, terminal or e-payment.
   */
  object:PaymentChargeType;

  /**
   * The brand of the card used or if the object is e-payment or terminal, this is a payment facilitator's name.
   */
  brand?: string;

  /**
   * Card token. This field is only set if register_card_token was specified in the payment token request request.
   */
  card_token?: string;
}

/**
 * result codes of payment status response according Visma Pay API
 * https://www.vismapay.com/docs/web_payments/?page=full-api-reference#payment-status-request
 */
export enum PaymentStatusResultCode {
  /**
   * Success
   */
  SUCCESS = 0,

  /**
   * Validation error or invalid token.
   */
  VALIDATION_ERROR = 1,

  /**
   * Payment failed.
   */
  FAILED = 2,

  /**
   * The payment is in incoming state and has not been finished by the customer. Try again later.
   */
  TRY_AGAIN = 3,

  /**
   * The status of the payment is reversed (authorized and then cancelled by the merchant).
   */
  REVERSED = 4,

  /**
   * Maintenance break.
   */
  MAINTENANCE_BREAK = 10,

}

/**
 * payment settled status
 */
export enum PaymentSettlmentStatus {

  /**
   * authorized / not settled
   */
  AUTHORIZED = 0,

  /**
   * settled
   */
  SETTLED = 1,
}

/**
 * Payment status return JSON object fields according Visma Pay API
 * https://www.vismapay.com/docs/web_payments/?page=full-api-reference#payment-status-request
 */
export interface PaymentStatusResult {

  /**
   * Result code.
   */
  result:PaymentStatusResultCode;

  /**
   * Only set when the transaction was successful (result = 0).
   * Defines whether the transaction was settled (e.g. billed from the credit card) or was the payment only authorized.
   */
  settled: PaymentSettlmentStatus;

  /**
   * Source object.
   */
  source: SourceObject;
}
