import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { StepProps } from '../RegistrationProgressFlow';
import { useTranslation } from 'next-i18next';
import FormElement from 'components/forms/FormElement';
import theme from 'theme';
import useMediaQuery from '@mui/material/useMediaQuery';

const Step2 = ({
  nextOnClick,
  form,
  events,
  messages,
}: StepProps) => {
  const {
    t,
  } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return <>
    <Grid container rowGap={2} alignItems='center'>

      <Grid item xs={12} sm={8}>
        <Typography variant='h1'>
          {t('Create a password')}
        </Typography>
      </Grid>

      {/* Help text */}
      <Grid item xs={12} sm={8}>
        <Typography variant='body1'>
          {t('Create yourself a password you can remember Press Next')}.
        </Typography>
      </Grid>

      <Grid container rowGap={4} marginTop={isMobile ? 3 : 5} columnGap={2} marginBottom={4}>
        <Grid container item rowGap={1} xs={12} sm={5}>
          <Grid item xs={12}>
            <Typography variant='body2'>
              {t('Password')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormElement field="password">
              <TextField
                value={form.values.password}
                id="password"
                type="password"
                {...events}
              />
            </FormElement>
          </Grid>
        </Grid>

        <Grid container item rowGap={1} xs={12} sm={5}>
          <Grid item xs={12}>
            <Typography variant='body2'>
              {t('Password again')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormElement field="password2">
              <TextField
                value={form.values.password2}
                id="password2"
                type="password"
                {...events}
              />
            </FormElement>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Button variant='contained' color='secondary' onClick={nextOnClick(2)} disabled={!!messages.password || !form.values.password.length ||
            !!messages.password2 || !form.values.password2.length}>
            {t('Next')}
          </Button>
        </Grid>

      </Grid>

      <Grid item xs={12} sm={12}>
        <Button variant='text' color='secondary' onClick={nextOnClick(0)}>
          <span className='button-text-underline' >{t('Back')}</span>
        </Button>
      </Grid>
    </Grid>
  </>;
};
export default Step2;
