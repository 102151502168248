/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-indent */
import { Show } from 'components/auth/Show';
import { useAuth } from '@mindhiveoy/react-auth';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import AppBar from '@mui/material/AppBar';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import NavbarDrawer from './NavbarDrawer';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import theme from 'theme';
import useMediaQuery from '@mui/material/useMediaQuery';
import useScreenContext from 'components/layout/useScreenContext';

import { MenuIcon, PersonIcon, SearchIcon } from 'components/common/Icons/Icons';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Drawer from '@mui/material/Drawer';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import Grid from '@mui/material/Grid';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';

const StyledAppBar = styled(AppBar)(({
  theme,
}) => `
  background-color: #FCFBFA;
  box-shadow: none;
  color: ${theme.palette.primary.contrastText};
  position: fixed; !important;
  left: 0;
  width: 100%;
  top: 50px;
  z-index: 49;
  background-image: none;
  border-bottom: 2px solid #F5F5F5;
`);

const StyleToolbar = styled(Toolbar)(({
  theme,
}) => `
  ${theme.breakpoints.down('sm')} {
   padding: ${theme.spacing(0, 2)};
  }
  padding: ${theme.spacing(1, 2)};
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #FCFBFA;
`);

const BlockLogo = styled.img(({
  theme,
}) => `
  ${theme.breakpoints.down('sm')} {
    max-height: 20px;
  }
  cursor: pointer;
  max-height: 30px;
`);

const LeftSide = styled.div`
  justify-self: flex-start;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CenterPart = styled.div`
  display: flex;
  flex: 2;
  height: 100%;
  gap: 20px;
  justify-content: center;
  & .MuiTypography-root {
    cursor: pointer;
  }
`;

const NavLinkBorder = styled.div`
  width: 0px;
  height: 2px;
  background-color: #1e1e1e;
  transition: width 1s;
  &.active {
    width: 100%;
    height: 2px;
    background-color: ${theme.palette.secondary.main};
  }
`;

const NavLink = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    ${NavLinkBorder} {
      width: 100%; 
    }    
  }
`;

const RightSide = styled.div`
  justify-self: flex-end;
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
`;

const PageTitle = styled(Typography)(({
  theme,
}) => `
  margin-right: ${theme.spacing(1)};
  margin-left: 30px;
`);

const ControlArea = styled.div(({
  theme,
}) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${theme.spacing()};
`);

const ActionsArea = styled.div(({
  theme,
}) => `
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:${theme.spacing()};
`);

const StyledBox = styled(Box)`
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(10, 6, 12, 6)};
  background-color: #000;
`;

const StyledIconButton = styled(IconButton)`
    &> .MuiSvgIcon-root {
      width: 40px;
      height: 40px;
    }
  ${theme.breakpoints.down('sm')} {
    height: 28px;
    width: 28px;
    &> .MuiSvgIcon-root {
      width: 28px;
      height: 28px;
    }
  }
`;

const SocialIconButton = styled(IconButton)`
  width: 34px;
  height: 34px;
  &> .MuiSvgIcon-root {
    width: 30px;
    height: 30px;
  }
`;

const DrawerLogo = styled.img`
  max-height: 28px;
`;

const StyledList = styled(List)`
  margin-bottom: ${theme.spacing(6)};
`;

const StyledListItem = styled(ListItem)`
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #fff;
  padding-left: 0;
  letter-spacing: 0.08px;
  &.strong {
    font-weight: 600;
  }
  &.active {
    border-bottom: 2px solid ${theme.palette.secondary.main};
  }
`;

export interface WithNavigationControls {
  controls?: JSX.Element[];
}
export interface NavbarProps extends WithNavigationControls {
  title?: string;
}
const Navbar = ({
  title = '',
}: NavbarProps): JSX.Element => {
  const {
    user,
    authenticationActivated,
  } = useAuth();

  /*  const userMenuOpen = Boolean(userMenuEl); */
  const [signingIn, setSigningIn,] = useState(false);
  const [userMenuEl, setUserMenuEl,] = useState(null);
  const [open, setOpen,] = useState(false);
  const [shoppingBagOpen, setShoppingBagOpen,] = useState(false);
  const [activeLink, setActiveLink,] = useState('');

  const {
    setUserProfileDrawerOpen,
  } = useScreenContext();

  const {
    t,
  } = useTranslation();
  const router = useRouter();
  const pathName = router.pathname;

  const isWeb = useMediaQuery(theme.breakpoints.up('md'));

  const handleUserProfileClick = useCallback((event) => {
    setUserMenuEl(event?.currentTarget);
    setUserProfileDrawerOpen(true);
  }, [setUserProfileDrawerOpen,]);

  const handleDrawerOpen = useCallback(() => {
    setOpen(!open);
  }, [open,]);

  const handleShoppingBagOpen = useCallback(() => {
    setShoppingBagOpen(!shoppingBagOpen);
  }, [shoppingBagOpen,]);

  const handleLogoClick = useCallback(() => {
    router.push('/');
  }, [router,]);

  const handleDrawerLogoClick = useCallback(() => {
    handleDrawerOpen();
    router.push('/');
  }, [router, handleDrawerOpen,]);

  const handleSignInClick = useCallback(() => {
    router.push({
      pathname: '/signin',
      query: {
        to: 'login',
      },
    });
    event.stopPropagation();
  }, [router,]);

  const handleSignUpClick = useCallback((event: React.MouseEvent) => {
    router.push({
      pathname: '/signin',
      query: {
        to: 'sign-up',
      },
    });
    event.stopPropagation();
  }, [router,]);

  const handleSignInClose = useCallback(() => {
    setSigningIn(false);
  }, []);

  const handleNavigationLinkClick = useCallback((link: string) => () => {
    if (link === 'orderServices') {
      router.push('/services');
    } else if (link === 'blog') {
      router.push('/blog');
    } else if (link === 'wayToOperate') {
      router.push('/our-way-to-operate');
    } else if (link === 'joinProvider') {
      router.push('/join-us');
    }
    handleDrawerOpen();
  }, [router, handleDrawerOpen,]);

  const clearUserMenuEl = useCallback(() => {
    setUserMenuEl(undefined);
  }, []);

  const buttonPosition = useMemo(() => {
    return {
      right: '-20px',
      zIndex: 2,
    };
  }, []);

  useEffect(() => {
    if (pathName == '/services' || pathName == '/service/[slug]' || pathName == '/order/[serviceId]') {
      setActiveLink('services');
    } else if (pathName == '/our-way-to-operate') {
      setActiveLink('our-way-to-operate');
    } else if (pathName == '/blog' || pathName == '/blog/[slug]') {
      setActiveLink('blog');
    } else if (pathName == '/') {
      setActiveLink('home');
    } else {
      setActiveLink('');
    }
  }, [pathName,]);

  return <>

    <StyledAppBar position="fixed">
      <StyleToolbar >
        <LeftSide>
          <BlockLogo onClick={handleLogoClick} src='/images/logos/block-logo-musta_beta.png'></BlockLogo>
          {title && <PageTitle variant="h6">
            {title}
          </PageTitle>}
          {/*  <Breadcrumbs/> */}
        </LeftSide>

        {isWeb &&
          <CenterPart>
            <NavLink onClick={handleNavigationLinkClick('orderServices')}>
              <Typography variant='body2' >{t('Order services')}</Typography>
              <NavLinkBorder className={activeLink == 'services' ? 'active' : ''} />
            </NavLink>
            <NavLink onClick={handleNavigationLinkClick('wayToOperate')}>
              <Typography variant='body2'>{t('Our way to operate')}</Typography>
              <NavLinkBorder className={activeLink == 'our-way-to-operate' ? 'active' : ''} />
            </NavLink>
            <NavLink onClick={handleNavigationLinkClick('blog')}>
              <Typography variant='body2'>{t('Blog')}</Typography>
              <NavLinkBorder className={activeLink == 'blog' ? 'active' : ''} />
            </NavLink>
          </CenterPart>}

        <RightSide>
          <ActionsArea>
            <ControlArea>
              <StyledIconButton
                color="info"
              >
                <SearchIcon />
              </StyledIconButton>

              {/* TODO: Enable this after the Pilot */}
              {/* Shopping bag */}
              {/* <>
                <Drawer
                  anchor='right'
                  open={shoppingBagOpen}
                  onClose={handleShoppingBagOpen}
                >
                  <ShoppingBagSummary shoppingBagOpen={handleShoppingBagOpen} />
                </Drawer>
                <StyledIconButton
                  onClick={handleShoppingBagOpen}
                  color="info"
                >
                  <ShoppingBag />
                </StyledIconButton>
              </> */}
            </ControlArea>

            <Show whenIdentifiedUser orRender={
              <>
                {!user || user.isAnonymous ?
                  isWeb ?
                    /* Web, not signed in */
                    <>
                      <Button style={buttonPosition} variant="contained" color='info' onClick={handleSignUpClick}>
                        {t('Sign up')}
                      </Button>
                      <Button variant="contained" color='lightgrey' onClick={handleSignInClick} className="button-contained-lightgrey">
                        {t('Log in')}
                      </Button>
                    </> :
                    <>

                      {/* Mobile, not signed in */}
                      <NavbarDrawer source='login' userMenuEl={userMenuEl} clearUserMenuEl={clearUserMenuEl} />
                      <StyledIconButton
                        onClick={handleUserProfileClick}
                        color="info"
                      >
                        <PersonIcon />
                      </StyledIconButton>
                    </> :
                  <CircularProgress color='secondary' />
                }
              </>
            }>
              <NavbarDrawer source='profile' userMenuEl={userMenuEl} clearUserMenuEl={clearUserMenuEl} />
              <Show whenIdentifiedUser>
                <StyledIconButton
                  onClick={handleUserProfileClick}
                  color="error"
                >
                  <PersonIcon />
                </StyledIconButton>
              </Show>
            </Show>

            {/* Mobile drawer */}
            {
              isWeb ?
                null :
                <>
                  <Drawer
                    anchor='left'
                    open={open}
                    onClose={handleDrawerOpen}
                  >
                    <StyledBox
                      role="presentation"
                    >
                      <Grid container justifyContent={'space-between'} marginBottom={9}>
                        <DrawerLogo onClick={handleDrawerLogoClick} src='/images/logos/block_logo_white.svg'></DrawerLogo>
                        <Grid item>
                          <StyledIconButton
                            color='info'
                            onClick={handleDrawerOpen}
                            onKeyDown={handleDrawerOpen}
                          >
                            <CloseIcon />
                          </StyledIconButton>
                        </Grid>
                      </Grid>

                      <StyledList>
                        <StyledListItem className='strong' onClick={handleDrawerLogoClick}>
                          {t('Home')}
                          <NavLinkBorder className={activeLink == 'home' ? 'active' : ''} />
                        </StyledListItem>
                        <StyledListItem className='strong' onClick={handleNavigationLinkClick('orderServices')}>
                          {t('Order services')}
                          <NavLinkBorder className={activeLink == 'services' ? 'active' : ''} />
                        </StyledListItem>
                        <StyledListItem className='strong' onClick={handleNavigationLinkClick('wayToOperate')}>
                          {t('Our way to operate')}
                          <NavLinkBorder className={activeLink == 'our-way-to-operate' ? 'active' : ''} />
                        </StyledListItem>
                        <StyledListItem className='strong' onClick={handleNavigationLinkClick('blog')}>
                          {t('Blog')}
                          <NavLinkBorder className={activeLink == 'blog' ? 'active' : ''} />
                        </StyledListItem>
                      </StyledList>

                      {/* TODO: Enable after the Pilot */}
                      {/*  <StyledList>
                        <StyledListItem onClick={handleNavigationLinkClick('joinProvider')}>
                          {t('Join as a service provider')}
                        </StyledListItem>
                        <StyledListItem onClick={handleNavigationLinkClick('forMedia')}>
                          {t('For Media')}
                        </StyledListItem>
                      </StyledList> */}

                      {/* TODO: Remove this after the social media links work */}
                      <Grid container marginBottom={2}>
                        <Grid item>
                          <Typography variant='body1' color={'#fff'}>
                            {t('Upcoming features')}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container gap={1}>
                        <SocialIconButton color='info'>
                          <FacebookOutlinedIcon />
                        </SocialIconButton>
                        <SocialIconButton color='info'>
                          <InstagramIcon />
                        </SocialIconButton>
                        <SocialIconButton color='info'>
                          <TwitterIcon />
                        </SocialIconButton>
                        <SocialIconButton color='info'>
                          <LinkedInIcon />
                        </SocialIconButton>
                        <SocialIconButton color='info'>
                          <YouTubeIcon />
                        </SocialIconButton>
                      </Grid>
                    </StyledBox>
                  </Drawer>
                  <StyledIconButton
                    onClick={handleDrawerOpen}
                    color="info"
                  >
                    <MenuIcon />
                  </StyledIconButton>
                </>
            }
          </ActionsArea>
        </RightSide>
      </StyleToolbar>
      {(!user || user.isAnonymous) && <Backdrop
        sx={{
          color: '#333', zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={!!(signingIn || authenticationActivated) || false}
        onClick={handleSignInClose}
      >
        <div>Sign in component here!!</div>
      </Backdrop>}
    </StyledAppBar>
  </>;
};

export default Navbar;
