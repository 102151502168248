import '../_dev/wdyr';
import '../public/styles/global.css';
import 'whatwg-fetch';

import { Theme as EmotionTheme, ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { ThemeProvider as MaterialThemeProvider, StylesProvider } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import React, { useEffect } from 'react';
// import * as Sentry from '@sentry/react';
import { AuthProvider, AuthenticationStrategy } from '@mindhiveoy/react-auth';
import { OrderProvider, defaultOrderState } from 'utils/hooks/useOrder';
import { PilotCodeProvider, defaultPilotCodeState } from 'utils/hooks/usePilotCode';
import { ServiceProvider, defaultServiceState } from 'utils/hooks/useServices';
import { SettingsProvider, defaultSettingsState } from 'utils/hooks/useSettings';
import { appWithTranslation } from 'next-i18next';
import { bootstrapRoles } from '@mindhiveoy/auth';
import { firebaseAuthPlatformAdapter } from 'utils/firebasePlatformAdapter';
import { initializeSlugs } from 'utils/slugs';
import { schema } from '@shared/schema/schema';
import CookieConsentComponent from 'components/dialogs/CookieConsentComponent';
import Head from 'next/head';
import Navbar from 'components/layout/Navbar';
import ScreenProvider from 'components/layout/ScreenProvider';
import SignIn from 'components/auth/SignIn';
import config from 'config';
import theme from 'theme';
import type { AppProps, NextWebVitalsMetric } from 'next/app';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
bootstrapRoles(schema.roles as any);

// TODO Setup Sentry
// config.environment.target.match(/^(staging|production)$/) && Sentry.init({
//   dsn: config.sentry.dsn,
// });

const App = (props: AppProps): JSX.Element => {
  const {
    Component,
    pageProps,
    router,
  } = props;

  const [state,] = React.useState({});

  initializeSlugs(pageProps.settings?.slugs);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <>
      <Head>
        <meta charSet='utf-8' />
        <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>
      <StylesProvider injectFirst>
        <MaterialThemeProvider theme={theme}>
          <EmotionThemeProvider theme={theme as EmotionTheme}>
            <CssBaseline />
            <ScreenProvider>
              <PilotCodeProvider state={defaultPilotCodeState}>
                <AuthProvider
                  adapter={firebaseAuthPlatformAdapter}
                  signIn={SignIn as unknown as never}
                  state={state}
                  strategy={AuthenticationStrategy.EXPLICIT}
                >
                  <Navbar />
                  <SettingsProvider settings={defaultSettingsState}>
                    <ServiceProvider state={defaultServiceState}>
                      <OrderProvider order={defaultOrderState}>
                        <Component {...pageProps} key={router.route} />
                      </OrderProvider>
                    </ServiceProvider>
                  </SettingsProvider>
                </AuthProvider>
              </PilotCodeProvider>
            </ScreenProvider>
            <CookieConsentComponent/>

          </EmotionThemeProvider>
        </MaterialThemeProvider>
      </StylesProvider>
    </>
  );
};

// eslint-disable-next-line require-jsdoc
export function reportWebVitals(metric: NextWebVitalsMetric): void {
  if (config.environment.target === 'development') {
    console.log(metric);
  }
  // TODO Report vitals
}

export default appWithTranslation(App);
