import { useAuth } from '@mindhiveoy/react-auth';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import useScreenContext from './useScreenContext';

const ProfileMenu = styled(Menu)(({
  theme,
}) => `
  & .MuiPaper-root {
    ${theme.breakpoints.down('sm')} {
      width: 45%;
      left: 50% !important;
    }
    border: 1px solid ${theme.palette.midgrey.main};
    width: 15%;
    position: absolute;
    top: 7rem !important;
    left: 82% !important;
  }
`);

interface NavbarDrawerProps {
  source: string;
  userMenuEl: any;
  clearUserMenuEl: () => void;
}

const NavbarDrawer = ({
  source,
  userMenuEl,
  clearUserMenuEl,
}: NavbarDrawerProps) => {
  const {
    userProfileDrawerOpen,
    setUserProfileDrawerOpen,
  } = useScreenContext();
  const {
    role,
    signOut,
  } = useAuth();

  const router = useRouter();

  const {
    t,
  } = useTranslation();

  const handleDrawerClose = useCallback(() => {
    clearUserMenuEl();
    setUserProfileDrawerOpen(false);
  }, [setUserProfileDrawerOpen, clearUserMenuEl,]);

  const handleMenuItemClick = useCallback((routeTag: string) => (): void => {
    if (routeTag === 'profile' || routeTag === 'admin' && role === 'a') {
      router.push(`/${routeTag}`);
    } else {
      router.push({
        pathname: '/signin',
        query: {
          to: `/${routeTag}`,
        },
      });
    }

    handleDrawerClose();
  }, [handleDrawerClose, router, role,]);

  const handleSignOut = useCallback(() => {
    signOut();
    router.push('/');
  }, [signOut, router,]);

  const renderDrawerContent = useCallback(() => {
    if (source === 'profile') {
      return <span>
        {role === 'a' &&
          <MenuItem value='ownAccount' onClick={handleMenuItemClick('admin')}>
            <Typography variant='body1' className='body-underlined-red'>{t('Admin')}</Typography>
          </MenuItem>}
        <MenuItem value='ownAccount' onClick={handleMenuItemClick('profile')}>
          <Typography variant='body1' className='body-underlined-red'>{t('My account')}</Typography>
        </MenuItem>
        <MenuItem value='logOut' onClick={handleSignOut}>
          <Typography variant='body1' className='body-underlined-red'>{t('Log out')}</Typography>
        </MenuItem>
      </span>;
    } else {
      return <span>
        <MenuItem value='login' onClick={handleMenuItemClick('login')}>
          <Typography variant='body1' className='body-underlined-red'>{t('Log in')}</Typography>
        </MenuItem>
        <MenuItem value='register' onClick={handleMenuItemClick('sign-up')}>
          <Typography variant='body1' className='body-underlined-red'>{t('Sign up')}</Typography>
        </MenuItem>
      </span>;
    }
  }, [handleMenuItemClick, handleSignOut, role, source, t,]);

  return <ProfileMenu
    id="menu-appbar"
    anchorEl={userMenuEl}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    keepMounted
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    open={userProfileDrawerOpen}
    onClose={handleDrawerClose}
  >
    {renderDrawerContent()}
  </ProfileMenu>;
};

export default NavbarDrawer;
