
import { CSSProperties, useMemo } from 'react';
import { FormObject, MessageFields, MessageType } from 'ts-react-form-validator';

interface FieldMessageProps<T> {
  messages?: MessageFields<T>;
  field: keyof T;
}

/**
 * Component showing a message for a specific field in form. If no message is
 * delivered, component will return null.
 *
 * @param {MessageField}  formMessage  Form message to show.
 * @return {FieldMessage} If message was found, component will be returned otherwise null.
 */
export const FieldMessage = <T extends FormObject>({
  messages,
  field,
}: FieldMessageProps<T>
): JSX.Element => {
  const fieldMessage = messages && messages[field];

  const fieldMessageStyle = useMemo(():CSSProperties => {
    return {
      color: fieldMessage && fieldMessage.type === MessageType.ERROR && '#E94926',
      fontSize: fieldMessage && fieldMessage.type === MessageType.ERROR && '0.8em',
      position: fieldMessage && fieldMessage.type === MessageType.ERROR ? 'absolute' : 'relative',
      marginTop: fieldMessage && fieldMessage.type === MessageType.ERROR && '55px',
      left: fieldMessage && fieldMessage.type === MessageType.ERROR && '10px',
    };
  },[fieldMessage,]);

  if (!fieldMessage) {
    return null;
  }

  return <div style={fieldMessageStyle}>
    {fieldMessage.message}
  </div>;
};

export default FieldMessage;
