/* eslint-disable valid-jsdoc */
import {
  ApartmentParameters,
  ApartmentPriceCategoryParameters,
  Currency, CustomerInfo, LaundryFormParameters, MovingFormParameters, Order, OrderRow, PriceCategoryParameters, ShortUserRoleId, User
} from '..';
import { UserId } from '@mindhiveoy/schema';
import { clone, uniqueId } from 'lodash';
import { roundCurrency } from './roundCurrency';

/**
 * The logic to calculate the order totals, VAT etc. for different kinds of services
 * @param {User<ShortUserRoleId>} user - The user
 */
export class OrderModel {
  private _orderRows: OrderRow[] = [];
  private _user?: User<ShortUserRoleId>;
  private _uid?: UserId | undefined;
  private _totalPrice: Currency = 0;
  private _totalPriceBeforeTaxes: Currency = 0;
  private _totalVat: Currency = 0;
  private _customerInfo: CustomerInfo = {};

  /**
   * Order base to store everything defined in order tht this class will not
   * alter. This is being used to derive a new order object based changes
   * defined by manipulations done with this class.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _orderBase: Omit<Order, 'rows' | 'totalPriceBeforeTaxes' | 'totalPrice' | 'totalVat' | 'uid' | 'customerInfo'> = {} as any;

  /**
   * Class constructor
   * @param {Order} order initial order object
   * @param {User<ShortUserRoleId>} user user object
   */
  constructor(order: Order, user: User<ShortUserRoleId>) {
    this.setUser(user);
    this.setCustomerInfo({
      firstName: user?.firstName,
      lastName: user?.lastName,
      phoneNumber: user?.phoneNumber,
      streetAddress: user?.streetAddress,
      postalCode: user?.postalCode,
      city: user?.city,
      userEmail: user?.email,
    });
    this.setUid(user?.uid);
    this.reset(order);
  }

  /**
   * getter for the order total price
   */
  public get totalPrice() {
    return this._totalPrice;
  }

  /**
   * getter for the order total VAT
   */
  public get totalVat() {
    return this._totalVat;
  }

  /**
   * getter for the order user
   */
  public get user() {
    return this._user;
  }

  /**
   * getter for the order customer info
   */
  public get customerInfo() {
    return this._customerInfo;
  }

  /**
   * getter for the order customer info
   */
  public get uid() {
    return this._uid;
  }

  /**
   * getter for the order total price before taxes
   */
  public get totalPriceBeforeTaxes() {
    return this._totalPriceBeforeTaxes;
  }

  public setCustomerInfo = (customerInfo: CustomerInfo) => {
    this._customerInfo = customerInfo;
  };

  public setUser = (user?: User<ShortUserRoleId>) => {
    this._user = user;
    this._calculateSums();
  };

  public setUid = (uid: UserId) => {
    this._uid = uid;
  };

  public getOrderRowById = (rowId: string): OrderRow | undefined => {
    if (!this._orderRows?.length) {
      return;
    }

    return this._orderRows.find((_row) => _row.rowId === rowId);
  };

  private _rowIndexOfId = (id: string): number => {
    // TODO optimize the id calculation
    return this._orderRows.findIndex((r) => this._id(r) === id);
  };

  /**
   * An unique id for the order row containing all things that can separate the order
   * be its own line.
   * @param row
   * @returns
   */
  private _id = (row: OrderRow): string => {
    return JSON.stringify({
      i: row.serviceId,
      p: row.pricingModel.name,
    });
  };

  /**
   * Add row to the order
   * @param {OrderRow} row
   *
   * @return {OrderRow} The row object added to the order.
   */
  public addRow = (row: OrderRow): OrderRow => {
    const id = this._id(row);
    const index = this._rowIndexOfId(id);

    let result: OrderRow;

    if (index >= 0) {
      const currentRow = this._orderRows[index];
      result = this._calculateSumsForOrderLine({
        ...row, rowId: currentRow.rowId,
      });
      this.replaceRow(result);
    } else {
      result = this._calculateSumsForOrderLine({
        ...row, rowId: uniqueId('orderRow'),
      });
      this._orderRows.push(result);
    }

    this._calculateSums();
    return result;
  };

  public updateRow = (row: Partial<OrderRow>): OrderRow => {
    const index = this._orderRows.findIndex((r) => r.rowId === row.rowId);
    if (index < 0) {
      throw new Error(`Row not found with id ${row.rowId} can not update.`);
    }

    const updatedOrderRow = {
      ...this._orderRows[index],
      ...row,
    };

    const result = this._calculateSumsForOrderLine(updatedOrderRow);
    this._orderRows.splice(index, 1, result);
    this._calculateSums();
    return result;
  };

  public replaceRow = (row: OrderRow): OrderRow => {
    const index = this._orderRows.findIndex((r) => r.rowId === row.rowId);
    if (index < 0) {
      throw new Error(`Row not found with id ${row.rowId} can not update.`);
    }

    const result = this._calculateSumsForOrderLine(row);
    this._orderRows.splice(index, 1, result);
    this._calculateSums();
    return result;
  };

  public deleteRow = (rowId: string) => {
    const index = this._orderRows.findIndex((r) => r.rowId === rowId);
    if (index < 0) {
      throw new Error(`Row not found with id ${rowId}. Nothing to delete.`);
    }
    this._orderRows.splice(index, 1);
    this._calculateSums();
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  private _calculateSumsForOrderLine = (newRow: OrderRow): OrderRow => {
    const updatedOrderRow = clone(newRow);
    switch (newRow.service.type) {
      case 'extra':
        updatedOrderRow.totalPrice = newRow.service?.pricingModel?.totalPrice;
        break;
      case 'laundry':
        const laundryParams = newRow?.userSpecifiedParameters as LaundryFormParameters;
        updatedOrderRow.totalPrice = laundryParams.selectedCountPriceCategories.reduce((accumulator, object) => {
          return accumulator + object.count * object.pricePerUnit;
        }, 0);
        const newSelectedCountPriceCategories = clone(laundryParams.selectedCountPriceCategories);
        laundryParams.selectedCountPriceCategories.forEach((item, i) => {
          newSelectedCountPriceCategories[i].totalPriceBeforeTaxes = roundCurrency(100 * item.pricePerUnit / (100 + updatedOrderRow.pricingModel.vatPercent));
        });
        if (updatedOrderRow.userSpecifiedParameters?.type === 'filledLaundryForm') {
          updatedOrderRow.userSpecifiedParameters.selectedCountPriceCategories = newSelectedCountPriceCategories;
        }
        break;
      case 'moving':
        const movingParams = newRow?.userSpecifiedParameters as MovingFormParameters;
        updatedOrderRow.totalPrice = movingParams.selectedCountPriceCategories.reduce((accumulator, object) => {
          if (object.type === 'movingArea') {
            return accumulator + object.totalPrice;
          }
          return accumulator + object.count * object.pricePerUnit;
        }, 0);
        updatedOrderRow.estimatedTime = 1; //  TODO: 1 hour by default hack to limit by the amount of moves per day
        const newSelectedCountPriceCategoriesMoving = clone(movingParams.selectedCountPriceCategories);
        movingParams.selectedCountPriceCategories.forEach((item, i) => {
          const itemPrice = item.type === 'movingArea' ? item.totalPrice : item.pricePerUnit;
          newSelectedCountPriceCategoriesMoving[i].totalPriceBeforeTaxes =
            roundCurrency(100 * itemPrice / (100 + updatedOrderRow.pricingModel.vatPercent));
        });
        if (updatedOrderRow.userSpecifiedParameters?.type === 'filledMovingForm') {
          updatedOrderRow.userSpecifiedParameters.selectedCountPriceCategories = newSelectedCountPriceCategoriesMoving;
        }
        break;
      case 'repair':
        const priceCategory = newRow?.selectedCountPriceCategory;
        if (priceCategory) {
          updatedOrderRow.estimatedTime = priceCategory.estimatedTime ?? 0;
          if (priceCategory?.unit === 'm2') {
            const renovationParams = newRow?.userSpecifiedParameters as ApartmentPriceCategoryParameters;
            updatedOrderRow.totalPrice = priceCategory.pricePerUnit * renovationParams.apartmentArea;
          } else {
            updatedOrderRow.totalPrice = priceCategory.pricePerUnit;
          }
        }
        break;
      case 'IT':
        updatedOrderRow.estimatedTime = newRow.subType?.estimatedTime ?? 0;
        // TODO: this will work only with current dataset; FIX this later
        newRow?.service?.pricingModel?.categories?.some((category) => {
          if (category.type === newRow.subType?.name) {
            updatedOrderRow.totalPrice = category.pricePerUnit;
            return true;
          }
          return false;
        });
        break;
      case 'massage':
      case 'interiorDesign':
      case 'grooming':
      case 'law':
        newRow?.service?.pricingModel?.categories?.some((category) => {
          const massageParams = newRow?.userSpecifiedParameters as PriceCategoryParameters;
          if (category.id === massageParams?.priceCategoryId) {
            updatedOrderRow.totalPrice = category.totalPrice;
            updatedOrderRow.estimatedTime = category.estimatedTime;
            return true;
          }
          return false;
        });
        break;
      case 'windowcleaning':
        const windowcleaningParams = newRow?.userSpecifiedParameters as ApartmentParameters;
        const newArea = windowcleaningParams?.apartmentArea;
        const selectedPriceCategory = newRow?.service?.pricingModel?.categories?.filter((category) => {
          return category.minArea <= newArea && newArea <= category.maxArea;
        });
        updatedOrderRow.totalPrice = selectedPriceCategory.length ? selectedPriceCategory[0].totalPrice : 0;
        updatedOrderRow.estimatedTime = selectedPriceCategory.length ? selectedPriceCategory[0].estimatedTime : 0;
        break;
      case 'cleaning':
        const cleaningParams = newRow?.userSpecifiedParameters as ApartmentPriceCategoryParameters;
        const area = cleaningParams?.apartmentArea ?? 0;
        const newSelectedPriceCategory = newRow?.service?.pricingModel?.categories?.filter((category) => {
          if (category.type === cleaningParams?.priceCategoryId) {
            return category.minArea <= area && area <= category.maxArea;
          }
          return false;
        });
        let lengthForArea = 0;
        newRow?.service?.pricingModel?.categories?.some((category) => {
          if (category.minArea <= area && area <= category.maxArea) {
            lengthForArea = category.estimatedTime;
            return true;
          }
          return false;
        });
        updatedOrderRow.totalPrice = newSelectedPriceCategory.length ? newSelectedPriceCategory[0].totalPrice : 0;
        updatedOrderRow.estimatedTime = newSelectedPriceCategory.length ? newSelectedPriceCategory[0].estimatedTime : lengthForArea;
        break;
    }

    /**
     * simple VAT calculations
     * Vero instructs to do the rounding on the final total price of the invoice
     * however, Visma Pay requires each row's pretax price & price to be an integer, so we have to do the rounding here
     */
    updatedOrderRow.totalPriceBeforeTaxes = roundCurrency(100 * updatedOrderRow.totalPrice / (100 + updatedOrderRow.pricingModel.vatPercent));
    updatedOrderRow.totalVat = roundCurrency(updatedOrderRow.totalPrice - updatedOrderRow.totalPriceBeforeTaxes);

    return {
      ...newRow,
      ...updatedOrderRow,
    };
  };

  /**
   * Get the order object with the current order state
   * @return {Order} order object
   */
  public getOrder = (): Order => {
    return {
      ...this._orderBase,
      rows: clone(this._orderRows),
      totalPrice: this._totalPrice,
      totalPriceBeforeTaxes: this._totalPriceBeforeTaxes,
      totalVat: this._totalVat,
      uid: this._uid ?? '',
      customerInfo: this._customerInfo,
    };
  };

  /**
   * Reset the object state based on given order
   *
   * @param {Order} order order object to reset state
   */
  public reset = (order: Order) => {
    this._orderBase = clone(order);
    this._calculateSums();
  };

  /**
   * Calculate all sums for the the order
   */
  private _calculateSums = () => {
    let totalPrice: Currency = 0;
    let totalPriceBeforeTaxes: Currency = 0;
    let totalVat: Currency = 0;
    this._orderRows.forEach((row) => {
      const calculatedRow = this._calculateSumsForOrderLine(row);
      totalPrice += calculatedRow.totalPrice;
      totalPriceBeforeTaxes += calculatedRow.totalPriceBeforeTaxes;
      totalVat += calculatedRow.totalVat;
    });

    this._totalPrice = roundCurrency(totalPrice);
    this._totalPriceBeforeTaxes = roundCurrency(totalPriceBeforeTaxes);
    this._totalVat = roundCurrency(totalVat);
  };
}
