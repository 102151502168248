import { Email, URL } from '@mindhiveoy/schema';
import { Media } from '@shared/schema';
import { MessageType } from 'ts-react-form-validator';
import { PhoneNumberUtil } from 'google-libphonenumber';
import validator from 'validator';

// TODO Move to shared code

export const phoneUtil = PhoneNumberUtil.getInstance();

export const validateMedia = (value: Media): true | {
  type: MessageType;
  message: string;
} => {
  if (!value) {
    return true;
  }
  switch (value.type) {
    case 'photo':
      return validateMediaUrl(value.photoURL);

    case 'video':
      return validateMediaUrl(value.videoURL);

    default:
  }
  return true;
};

export const validateMediaUrl = (value: URL): true | {
  type: MessageType;
  message: string;
} => {
  if (!value) {
    return true;
  }

  return validator.isURL(value) || {
    type: MessageType.ERROR,
    message: 'Invalid URL address for photo.',
  };
};

export const validateEmail = (value: Email): true | {
  type: MessageType;
  message: string;
} => {
  if (!value) {
    return true;
  }
  return validator.isEmail(value) || {
    type: MessageType.ERROR,
    message: 'Email is invalid.',
  };
};

export const validatePassword = (value: string): true | {
  type: MessageType;
  message: string;
} => {
  if (!value) {
    return true;
  }
  return validator.isLength(value, {
    min: 6,
  }) || {
    type: MessageType.ERROR,
    message: 'Password must be at least 6 characters',
  };
};

export const validatePhoneNumber = (value: string): true | {
  type: MessageType;
  message: string;
} => {
  if (!value) {
    return true;
  }

  try {
    const phoneNumber = phoneUtil.parse(value, 'FI');
    return phoneUtil.isValidNumberForRegion(phoneNumber, 'FI') || {
      type: MessageType.ERROR,
      message: 'Please enter a valid Finnish phone number',
    };
  } catch (error) {
    return {
      type: MessageType.ERROR,
      message: 'Please enter a valid Finnish phone number',
    };
  }
};

export const validatePostalCode = (value: string): true | {
  type: MessageType;
  message: string;
} => {
  if (!value) {
    return true;
  }

  return validator.isPostalCode(value, 'FI') || {
    type: MessageType.ERROR,
    message: 'Please enter a correct Finnish postal code',
  };
};

export const validateTermsOfServiceAndPrivacyPolicy = (value: boolean): true | {
 type: MessageType;
 message: string;
} => {
  if (value === undefined || value === null) {
    return true;
  }

  return !!value || {
    type: MessageType.ERROR,
    message: 'Terms and Service and Privacy Policy needs to be accepted.',
  };
};
