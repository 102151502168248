import { Variants } from 'framer-motion';

export const cardsVariants: Variants = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.4,
      staggerChildren: .5,
      delayChildren: .5,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.4,
      when: 'beforeChildren',
      delayChildren: 0.3,
      staggerChildren: 0.3,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.7,
    transition: {
      duration: 0.4,
      delayChildren: 0.3,
      staggerChildren: 0.3,
      when: 'afterChildren',
    },
  },
};

export const formVariants: Variants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
  },
};

export const cardVariants: Variants = {
  hidden: {
    opacity: 0,
    rotateX: 10,
    rotateZ: 5,
    scale: 0.9,
    transition: {
      duration: 0.5,
    },
  },
  visible: {
    opacity: 1,
    rotateX: 0,
    rotateZ: 0,
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.9,
    transition: {
      duration: 0.3,
    },
  },
};

export const commentVariants: Variants = {
  hidden: {
    opacity: 0,
    scale: 0.6,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.2,
      staggerChildren: 0.1,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.5,
    transition: {
      duration: 0.2,
    },
  },
};
