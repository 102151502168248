import React, { MouseEventHandler, useCallback, useState } from 'react';
/* import useOrder from 'reducers/order/useOrder'; */
import Stepper from '@mui/material/Stepper';

import { Form, MessageFields } from 'ts-react-form-validator';
import { SignUpForm } from './SignIn';
import { useTranslation } from 'next-i18next';
import Step1 from './RegistrationStepComponents/Step1';
import Step2 from './RegistrationStepComponents/Step2';
import Step3 from './RegistrationStepComponents/Step3';
import Step4 from './RegistrationStepComponents/Step4';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import theme from 'theme';
import useMediaQuery from '@mui/material/useMediaQuery';

const StepperContainer = styled.div(({
  theme,
}) => `
  ${theme.breakpoints.down('lg')} {
    padding: ${theme.spacing(2)};
    width: 100%;
    height: max-content;
  }
  width: 50%;
  height: 100vh;
  padding: ${theme.spacing(8)};
`);

const StepsRow = styled.div(({
  theme,
}) => `
  ${theme.breakpoints.down('lg')} {
    padding-bottom: ${theme.spacing(3)};
  }
  display: flex;
  gap: ${theme.spacing(1)};
  padding-bottom: ${theme.spacing(6)};
`);

const StepNumber = styled.div<{
  active: boolean
}>(({
  active,
}) => `
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  color: ${active ? '#1E1E1E' : '#6A6A6A80'};
  border: 1px solid ${active ? '#1E1E1E' : '#6A6A6A80'}
`);

export const ImageContainer = styled.div`
  ${theme.breakpoints.down('lg')} {
    width: 100%;
    height: auto;
  }
  width: 50%;
  height: 100vh;
  position: relative;
`;

export const Image = styled.img`
  ${theme.breakpoints.down('lg')} {
    height: 600px;
  }
  ${theme.breakpoints.down('sm')}{
    height: 200px;
  }
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 100% 0;
  z-index: -1;
`;

export const ImageText = styled(Typography)`
  ${theme.breakpoints.down('md')} {
    font-size: 0.9375rem;
    line-height: 1.3125rem;
  }
  width: 70%;
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.375rem;
  text-align: center;
  color: #fff;
  z-index: 5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export enum StepType {
  EMAIL = 0,
  PASSWORD = 1,
  CONTACT_INFO = 2,
  VERIFICATION = 3
}

export interface StepProps {
  nextOnClick: (index: number) => MouseEventHandler<HTMLButtonElement>;
  cancelOnClick: (event?: React.MouseEvent<HTMLInputElement>) => void;
  registerOnClick?: (event?: React.MouseEvent<HTMLInputElement>) => void;
  form: Form<SignUpForm>;
  events: any;
  messages: MessageFields<SignUpForm>;
}

interface RegistrationProgressFlowProps {
  cancelOnClick: (event?: React.MouseEvent<HTMLInputElement>) => void;
  registerOnClick: (event?: React.MouseEvent<HTMLInputElement>) => void;
  form: Form<SignUpForm>;
  events: any;
  messages: MessageFields<SignUpForm>;
}

export const RegistrationProgressFlow = ({
  cancelOnClick,
  registerOnClick,
  form,
  events,
  messages,
}: RegistrationProgressFlowProps) => {
  const {
    t,
  } = useTranslation();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const [selectedIndex, setSelectedIndex,] = useState<number>(0);
  const steps = [1, 2, 3, 4,]; // TODO: an ugly solution for now, needs to changed later so the component is reusable

  const handleNextOnClick = useCallback((index) => () => {
    index === 3 && registerOnClick();
    if (index >= 0 || index <= 3) {
      setSelectedIndex(index);
    }
  }, [registerOnClick,]);

  const getStepImage = (step: StepType) => {
    switch (step) {
      case StepType.EMAIL:
        return <>
          <ImageText>
            {t('You can acquire all everyday services easily, quickly and reliably from Block')}.
          </ImageText>
          <Image src={'/images/photos/registration/block_rekist_720x720_1.jpg'} />
        </>;
      case StepType.PASSWORD:
        return <>
          <ImageText>
            {t('By using our services you get more free time for yourself and your family')}.
          </ImageText>
          <Image src={'/images/photos/registration/block_rekist_720x720_2.jpg'} />
        </>;
      case StepType.CONTACT_INFO:
        return <>
          <ImageText>
            {t('What would you do with extra time')}?
          </ImageText>
          <Image src={'/images/photos/registration/block_rekist_720x720_3.jpg'} />
        </>;
      case StepType.VERIFICATION:
        return <>
          <Image src={'/images/photos/registration/block_rekist_720x720_3.jpg'} />
        </>;
      default:
        return null;
    }
  };

  const getStepContent = (step: StepType) => {
    switch (step) {
      case StepType.EMAIL:
        return <Step1 cancelOnClick={cancelOnClick} nextOnClick={handleNextOnClick} form={form} events={events} messages={messages} />;
      case StepType.PASSWORD:
        return <Step2 cancelOnClick={cancelOnClick} nextOnClick={handleNextOnClick} form={form} events={events} messages={messages} />;
      case StepType.CONTACT_INFO:
        return <Step3 cancelOnClick={cancelOnClick} nextOnClick={handleNextOnClick} form={form} events={events} messages={messages}
          registerOnClick={registerOnClick} />;
      case StepType.VERIFICATION:
        return <Step4 />;
      default:
        return null;
    }
  };
  return <div style={{
    display: 'flex',
    flexDirection: isTablet ? 'column' : 'row', // TODO: create a separate styled container for this
  }}>
    <ImageContainer>
      {getStepImage(selectedIndex)}
    </ImageContainer>

    <StepperContainer>
      <StepsRow>
        {steps.map((step) => {
          return <StepNumber active={step <= selectedIndex + 1} key={step}>{step}</StepNumber>;
        })}
      </StepsRow>
      <Stepper alternativeLabel activeStep={selectedIndex} >
        {getStepContent(selectedIndex)}
      </Stepper>
    </StepperContainer>
  </div>;
};
export default RegistrationProgressFlow;
