/* eslint-disable sonarjs/no-small-switch */
import { TFunction } from 'next-i18next';

const translateErrorCodeToHumanReadableError = (errorCode: string, t: TFunction): string => {
  switch (errorCode) {
    case 'Firebase: Error (auth/user-not-found).':
      return t('auth-user-not-found');
    case 'Wrong email address or password':
      return t('Wrong email address or password');
    default:
      return t(errorCode);
  }
};
export default translateErrorCodeToHumanReadableError;
