import { MessageField, MessageFields } from 'ts-react-form-validator';
import { Skeleton } from '@mui/material';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import FormMessage from './messages/FormMessage';
import React, { PropsWithChildren, useMemo } from 'react';
import Typography from '@mui/material/Typography';
import styled$ from 'utils/styledTransient';

// TODO: change min-width to use clamp
// TODO: find a place for default within the theme
const StyledPaper = styled$(motion.div)<{
  $widthPercentage?: number
}>(({
  $widthPercentage,
  theme,
}) => css`
  position: relative;
  align-items: flex-start;
  display: flex;
  border-radius: 0;
  flex-direction: column;
 /*  overflow-y: auto;
  overflow-x: hidden; */
  box-sizing: border-box;
  width: ${$widthPercentage}%;
 /*  max-width: 1440px; */
  margin: auto;
  justify-content: center;
  height: 100%;
  @media (max-width: 600px) {
    width: 100%;
    min-height: max-content;
    padding: ${theme.spacing(0, 2)} !important;
    justify-content: flex-start;
    overflow: auto;
  }
`);

export interface FormContext {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  messages?: MessageFields<any>;
}
export const FormContext = React.createContext<FormContext>(
  {}
);

export interface FormProps<T> {
  title?: string;
  id: string;
  layoutId?: string;
  loading?: boolean;
  message?: true | MessageField;
  messages?: MessageFields<T>;
  widthPercentage?: 50 | 75 | 100;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Form = <T,>({
  title,
  id,
  layoutId,
  message,
  loading = false,
  messages,
  children,
  widthPercentage = 100,
}: PropsWithChildren<FormProps<T>>): JSX.Element => {
  const context = useMemo(() => {
    return {
      messages,
    };
  }, [messages,]);
  return <FormContext.Provider value={context}>
    <StyledPaper
      $widthPercentage={widthPercentage}
      key={id}
      layoutId={layoutId ?? id}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      {title && <Typography variant="h1">{title}</Typography>}

      {message && <FormMessage formMessage={message} />}
      {loading ? <Skeleton animation="wave" /> : children}
    </StyledPaper>
  </FormContext.Provider>;
};

export default Form;
