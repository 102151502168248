
/**
 * Currency rounding in Finland by VERO website
 * VAT count is calculated 2 decimal precision.
 *
 * Veron määrä ilmoitetaan kahden desimaalin tarkkuudella. Matemaattisen pyöristyssäännön mukaan,
 * kun desimaaliluku katkaistaan, niin viimeinen mukaan tuleva numero korotetaan yhdellä,
 * jos ensimmäinen pois jäävä numero on 5 tai sitä suurempi numero.
 * Pyöristys tehdään laskun loppusumman osalta.
 * Read more from: https://www.vero.fi/syventavat-vero-ohjeet/ohje-hakusivu/48090/laskutusvaatimukset_arvonlisaverotukses/
 *
 * @param {number} amount Currency amount in cents
 * @return {number} Rounded currency
 */
export const roundCurrency = (amount: number): number => {
  // Throw error if amount type is something else than number
  if (typeof amount !== 'number') {
    throw new Error(`Currency type should be number, now it is ${typeof amount}`);
  }

  // https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
  return Math.round(amount + Number.EPSILON);
};
