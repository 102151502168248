
/* eslint-disable require-jsdoc */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, {
  KeyboardEventHandler, useCallback, useEffect, useMemo, useState
} from 'react';
import styled from '@emotion/styled';
import useForm, { FormValidationRules, MessageType, ValidationMessage } from 'ts-react-form-validator';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { FeatureId, PlanId, ShortUserRoleId, User } from '@shared/schema';
import { LayoutGroup, motion } from 'framer-motion';
import { LoginProps, useAuth } from '@mindhiveoy/react-auth';
import { ModuleId } from '@shared/schema/modules';
import { PhoneNumberFormat } from 'google-libphonenumber';
import { firebaseApp } from 'schema';
import { formVariants } from 'utils/variants';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  phoneUtil, validateEmail, validatePassword, validatePhoneNumber, validatePostalCode
} from 'utils/formValidators';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Alert from 'components/info/Alert';
import Center from 'components/common/Center';
import Form from 'components/forms/Form';
import RegistrationProgressFlow from './RegistrationProgressFlow';

import { browserLocalPersistence, browserSessionPersistence, getAuth, setPersistence } from 'firebase/auth';
import translateErrorCodeToHumanReadableError from 'utils/forms/errorMessages';

interface LoginForm {
  email: string;
  password: string;
}

export interface SignUpForm {
  email: string;
  password: string;
  password2: string;
  firstName: string;
  lastName: string;
  streetAddress: string;
  postalCode: string;
  city: string;
  phoneNumber: string;
}

export const LayoutElement = ({
  layoutId, children,
}) => <motion.div
  layout
  style={{
    width: '100%',
  }}
  layoutId={layoutId}
  variants={formVariants}
  initial="hidden"
  animate="visible"
  exit="hidden"
> {children} </motion.div>;

export const FullScreen = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const FormLayout = ({
  children,
  onClick,
}) => <motion.div
  variants={formVariants}
  initial={false}
  animate="visible"
  exit="hidden"
  key="login"
  onClick={onClick}
  style={{
    width: '100%',
  }}
> {children} </motion.div>;

const app = firebaseApp();
const functions = getFunctions(app);

const signUp = httpsCallable<any, any>(
  functions, 'signUpUserWithEmail'
);

export const SignIn: React.FunctionComponent<Partial<LoginProps<PlanId, ModuleId, FeatureId, ShortUserRoleId, User<ShortUserRoleId>>>> = ({
  authState,
}) => {
  const [method, setMethod,] = useState<'login' | 'sign-up'>('login');

  const {
    t,
  } = useTranslation();

  const router = useRouter();

  const requiredText = useCallback(
    (): string | any => t('Required field'),
    [t,]
  );

  const handleFormAreaClick = useCallback((event: React.MouseEvent) => {
    // Hijack all bubbling click events to prevent closing of overlay when signing in
    event.stopPropagation();
  }, []);

  // for changing the route according to params if action is triggered from navbar
  useEffect(() => {
    if (router.query?.to === '/sign-up' || router.query?.to === 'sign-up') {
      setMethod('sign-up');
    } else {
      setMethod('login');
    }
  }, [router, router.query.to,]);

  return (
    <FullScreen>
      <Center>
        {method === 'login' &&
          <FormLayout
            key='login-form'
            onClick={handleFormAreaClick}
          >
            <LayoutGroup>
              <LoginForm />
            </LayoutGroup>
          </FormLayout>}

        {method === 'sign-up' &&

          <LayoutGroup>
            <RegisterForm />
          </LayoutGroup>
        }
      </Center>
    </FullScreen>
  );

  /**
   * Login form view
   *
   * @return {React.ReactElement} Login form
   */
  function LoginForm() {
    const [error, setError,] = useState<string>(undefined);
    const [rememberMe, setRememberMe,] = useState<boolean>(false);

    const auth = useAuth();

    const router = useRouter();

    const {
      // signInWIthCredentials,
      signInWithEmail,
    } = authState ?? auth;

    const rules = useMemo((): FormValidationRules<LoginForm> => {
      return {
        fields: {
          email: {
            required: true,
            requiredText,
            validate: validateEmail,
          },
          password: {
            required: true,
            requiredText,
            trim: true,
          },
        },
        validateForm: (form): true | any => {
          const messages = {};
          return {
            ...form,
            messages,
          };
        },
      };
    }, []);

    const {
      form, events, messages, values: {
        email, password,
      },
    } = useForm<LoginForm>(
      {
        email: '',
        password: '',
      },
      rules
    );

    /* Auto scroll to top */
    useEffect(() => {
      if (typeof window === 'undefined') {
        return;
      }
      window.scrollTo(0, 0);
    }, []);

    const handleSignInClick = useCallback(async () => {
      if (!form.isFormValid) {
        return;
      }

      try {
        const authFB = getAuth(app);
        await setPersistence(authFB, rememberMe ? browserLocalPersistence : browserSessionPersistence);

        // Sign in with email pass combo.
        await signInWithEmail(email, password);
        // TODO: change this if needed
        if (router.pathname === '/signin') {
          router.push('/');
        }
        // If next url param is set, go there
      } catch (error) {
        setError(translateErrorCodeToHumanReadableError(error.message, t));
      }
    }, [email, form.isFormValid, password, rememberMe, router, signInWithEmail,]);

    const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = useCallback((event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
        handleSignInClick();
      }
    }, [handleSignInClick,]);

    const handleRegisterClick = useCallback((event: React.MouseEvent) => {
      setMethod('sign-up');
      event.stopPropagation();
    }, []);

    const handleResetPasswordClick = useCallback(() => {
      router.push('reset_password');
    }, [router,]);

    const handleRememberMeOnChange = useCallback((event: React.SyntheticEvent, checked: boolean) => {
      setRememberMe(checked);
    }, [setRememberMe,]);

    return <Form<LoginForm> widthPercentage={50} messages={messages} title={t('Login')} id="sign-in-form">
      <Grid container gap={2} marginTop={3}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={4}>
            <TextField
              label={t('Email')}
              value={email}
              id="email"
              type='email'
              autoCorrect='off'
              autoCapitalize='off'
              {...events}
              onKeyUp={handleKeyUp} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label={t('Password')}
              value={password}
              id="password"
              type="password"
              {...events}
              onKeyUp={handleKeyUp} />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button onClick={handleSignInClick} disabled={!form.isFormValid} variant='contained' color='secondary'>
              {t('Log in')}
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={2} marginBottom={3} alignItems='center'>
          <Grid item xs={12} sm={4} textAlign='center'>
            <FormControlLabel control={<Checkbox />} label={t('Remember me')} onChange={handleRememberMeOnChange} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button onClick={handleResetPasswordClick} variant='text' color='secondary'>
              <span className='button-text-underline'>{t('Forgot password')}</span>
            </Button>
          </Grid>
        </Grid>

        {error && <Alert visible={!!error}>{error}</Alert>}

        <Grid container justifyContent='center' display='inline-flex' alignItems='center'>
          <Grid item xs='auto'>
            <Typography variant="body1">
              {/* TODO: move this to a separate spacer-component if needed elsewhere */}
              <span style={{
                marginRight: '0.5rem',
              }}>{t('No credentials?')}
              </span>
            </Typography>
          </Grid>
          <Grid item xs='auto'>
            <Button onClick={handleRegisterClick} color="secondary" variant="text">
              <span className='button-text-underline'>{t('Register as a user')}</span>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Form>;
  }

  /**
   * Register form view
   *
   * @return {React.ReactElement} Register form
   */
  // eslint-disable-next-line sonarjs/cognitive-complexity
  function RegisterForm() {
    const auth = useAuth();
    const {
      signInWithEmail,
    } = authState ?? auth;

    const [error, setError,] = useState<string>(undefined);

    const rules = useMemo((): FormValidationRules<SignUpForm> => {
      return {
        fields: {
          email: {
            required: true,
            requiredText,
            validate: validateEmail,
          },
          password: {
            required: true,
            requiredText,
            trim: true,
            validate: validatePassword,
          },
          password2: {
            required: true,
            requiredText,
            trim: true,
          },
          firstName: {
            required: true,
            requiredText,
            trim: true,
          },
          lastName: {
            required: true,
            requiredText,
            trim: true,
          },
          streetAddress: {
            required: true,
            requiredText,
            trim: true,
          },
          postalCode: {
            required: true,
            requiredText,
            trim: true,
            validate: validatePostalCode,
          },
          city: {
            required: true,
            requiredText,
            trim: true,
          },
          phoneNumber: {
            required: true,
            requiredText,
            trim: true,
            validate: validatePhoneNumber,
          },
        },
        validateForm: (form): true | any => {
          const messages = {} as any;
          let isFormValid = form.isFormValid;

          let formMessage: ValidationMessage | undefined;

          if (form.filled.password && form.filled.password2 &&
            form.values.password !== form.values.password2) {
            formMessage = {
              type: MessageType.ERROR,
              message: 'Passwords do not match',
            };
            messages.password2 = formMessage;
            isFormValid = false;
          }
          return {
            ...form,
            isFormValid,
            messages,
            formMessage,
          };
        },
      };
    }, []);

    const {
      form, events, messages, values: {
        email, password, firstName, lastName,
        streetAddress, postalCode, city, phoneNumber,
      },
    } = useForm<SignUpForm>(
      {
        email: '',
        password: '',
        password2: '',
        firstName: '',
        lastName: '',
        streetAddress: '',
        postalCode: '',
        city: '',
        phoneNumber: '',
      },
      rules
    );

    const handleBackClick = useCallback((event: React.MouseEvent) => {
      router.push('/');
      event.stopPropagation();
    }, []);

    const handleRegisterClick = useCallback(async (event?: React.MouseEvent) => {
      if (!form.isFormValid) {
        return;
      }

      const number = phoneUtil.parseAndKeepRawInput(phoneNumber, 'FI');
      const formattedNumber = phoneUtil.format(number, PhoneNumberFormat.E164);

      try {
        await signUp({
          email,
          password,
          displayName: `${firstName} ${lastName}`,
          firstName,
          lastName,
          phoneNumber: formattedNumber,
          streetAddress,
          postalCode,
          city,
        });
        await signInWithEmail(email, password);
      } catch (error) {
        console.log('Error', error);
        setError(translateErrorCodeToHumanReadableError(error.message, t));
      }
      event?.stopPropagation();
    }, [city, email, firstName, form.isFormValid, lastName, password, phoneNumber, postalCode, signInWithEmail, streetAddress,]);

    return <>
      <Form<SignUpForm> id="sign-in-form" widthPercentage={100} messages={messages}>
        <RegistrationProgressFlow
          cancelOnClick={handleBackClick}
          registerOnClick={handleRegisterClick}
          form={form}
          events={events}
          messages={messages}
        />
        {error && <Alert visible={!!error}>{error}</Alert>}
      </Form>
    </>;
  }
};

export default SignIn;
