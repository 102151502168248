/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext, useCallback, useContext, useEffect, useState
} from 'react';

export const LS_PILOT_CODE_FIELD = 'pilotCodeEntered';
export const PILOT_CODE = 'block2022';
export const PILOT_CODE_2 = 'Block2022';

export interface PilotCodeAppContext {
  pilotCodeEntered: boolean;
  isLoading: boolean;
  verifyPilotCode: (value: string) => boolean;
}

const PilotCodeContext = createContext<PilotCodeAppContext>({
  pilotCodeEntered: false,
  isLoading: true,
  verifyPilotCode: () => false,
});

interface PilotCodeProviderTypes {
  state: PilotCodeAppContext;
  children: JSX.Element | JSX.Element[];
}

export const defaultPilotCodeState: PilotCodeAppContext = {
  pilotCodeEntered: false,
  isLoading: true,
  verifyPilotCode: () => false,
};

export const PilotCodeProvider = ({
  state, children,
}: PilotCodeProviderTypes) => {
  const [pilotCodeEntered, setPilotCodeEntered,] = useState<boolean>(state.pilotCodeEntered);
  const [isLoading, setIsLoading,] = useState<boolean>(state.isLoading);

  // check from local storage if the pilot code was already entered previously
  useEffect(() => {
    const pilotCodeEnteredFromLocalStorage = localStorage.getItem(LS_PILOT_CODE_FIELD);
    if (pilotCodeEnteredFromLocalStorage) {
      setPilotCodeEntered(true);
    }
    setIsLoading(false);
  }, []);

  /**
   * verify that the entered pilot code was correct, and update the bool value if yes,
   * also save it to LocalStorage
   */
  const verifyPilotCode = useCallback((code: string) => {
    if (code === PILOT_CODE || code === PILOT_CODE_2) {
      // save to local storage
      try {
        localStorage.setItem(LS_PILOT_CODE_FIELD, 'true');
      } catch (error: unknown) {
        console.error(error);
      }

      setPilotCodeEntered(true);
    }
    return code === PILOT_CODE || code === PILOT_CODE_2;
  }, []);

  return <PilotCodeContext.Provider value={{
    pilotCodeEntered,
    isLoading,
    verifyPilotCode,
  }}>{children}</PilotCodeContext.Provider>;
};

export const usePilotCode = () => useContext(PilotCodeContext);

export default PilotCodeContext;
