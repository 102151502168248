/* eslint-disable valid-jsdoc */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  CountPriceCategory,
  OrderModel, PaymentStyle, PricingModel, Service, ServiceSubType
} from '@shared/schema';
import {
  Order, OrderRow, OrderStatus, PaymentStatus, UserSpecifiedParameters
} from '@shared/schema/orders';
import { useAuth } from '@mindhiveoy/react-auth';
import { useServices } from './useServices';
import React, {
  createContext, useCallback, useContext, useEffect, useState
} from 'react';

export interface AppOrderContext {
  currentOrder: Order | undefined;
  setCurrentOrder(currentOrder: Order);
  addOrderRow: (service: Service, pricingModel: PricingModel, subType?: ServiceSubType, userSpecifiedParameters?: UserSpecifiedParameters,
    selectedCountPriceCategory?: CountPriceCategory) => string;
  updateOrderRow: (orderRow: OrderRow) => void;
  getOrderRowById: (orderRowId: string) => OrderRow | undefined;
  deleteOrderRowByServiceId: (serviceId: string) => void;
}

const OrderContext = createContext<AppOrderContext>({
  currentOrder: undefined,
  setCurrentOrder: () => { },
  addOrderRow: () => '',
  updateOrderRow: () => { },
  getOrderRowById: () => undefined,
  deleteOrderRowByServiceId: () => { },
});

interface OrderProviderTypes {
  order: AppOrderContext;
  children: JSX.Element | JSX.Element[];
}

/**
 * Prepare an empty order object with fields initialized conserning
 * the ordering customer.
 */
export const prepareOrderObject = (): Order => {
  return {
    rows: [],
    totalPriceBeforeTaxes: 0,
    totalPrice: 0,
    totalVat: 0,
    creationDate: 0,
    customerInfo: {},
    uid: '',
    status: OrderStatus.NOT_CONFIRMED,
    paymentId: '',
    paymentStatus: PaymentStatus.IN_PROGRESS,
    paymentStyle: PaymentStyle.VISMA_PAY,
  };
};

export const prepareOrderRowObject = (): Omit<OrderRow, 'service' | 'pricingModel'> &
  Partial<Pick<OrderRow, 'service' | 'pricingModel'>> => {
  return {
    serviceId: '',
    estimatedTime: 0,
    totalPriceBeforeTaxes: 0,
    totalVat: 0,
    totalPrice: 0,
    bookedDate: '',
  };
};

export const defaultOrderState: AppOrderContext = {
  currentOrder: prepareOrderObject(),
  setCurrentOrder: () => { },
  addOrderRow: () => '',
  updateOrderRow: () => { },
  getOrderRowById: () => undefined,
  deleteOrderRowByServiceId: () => { },
};

let orderModel: OrderModel;

export const OrderProvider = ({
  order, children,
}: OrderProviderTypes) => {
  const [currentOrder, setCurrentOrder,] = useState<Order | undefined>(order.currentOrder);

  const {
    user,
  } = useAuth();

  const {
    selectedService,
  } = useServices();

  useEffect(() => {
    // TODO: fix the typing
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    orderModel = new OrderModel(currentOrder, user as any);
    const newCurrentOrder = orderModel.getOrder();
    setCurrentOrder(newCurrentOrder);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService, user,]);

  const addOrderRow = useCallback((service: Service, pricingModel: PricingModel,
                                   subType?: ServiceSubType, userSpecifiedParameters?: UserSpecifiedParameters,
                                   selectedCountPriceCategory?: CountPriceCategory): string => {
    const row = prepareOrderRowObject();
    row.service = service;
    row.pricingModel = pricingModel;
    if (subType) row.subType = subType;
    if (userSpecifiedParameters) row.userSpecifiedParameters = userSpecifiedParameters;
    if (selectedCountPriceCategory) row.selectedCountPriceCategory = selectedCountPriceCategory;
    row.serviceId = service.id;
    const rowToAdd = row as OrderRow;
    const orderRow = orderModel.addRow(rowToAdd);
    const orderId = orderRow.rowId;
    const newCurrentOrder = orderModel.getOrder();
    setCurrentOrder(newCurrentOrder);
    return orderId;
  }, []);

  const updateOrderRow = useCallback((orderRow: OrderRow) => {
    orderModel.updateRow(orderRow);
    const newCurrentOrder = orderModel.getOrder();
    setCurrentOrder(newCurrentOrder);
  }, []);

  const deleteOrderRowByServiceId = useCallback((serviceId: string) => {
    const rows = orderModel.getOrder().rows;
    const rowToDelete = rows.find((row) => row.serviceId === serviceId);
    if (rowToDelete) {
      orderModel.deleteRow(rowToDelete.rowId);
      const newCurrentOrder = orderModel.getOrder();
      setCurrentOrder(newCurrentOrder);
    }
  }, []);

  const getOrderRowById = useCallback((orderRowId: string) => {
    return orderModel.getOrderRowById(orderRowId);
  }, []);

  return <OrderContext.Provider value={{
    currentOrder,
    setCurrentOrder,
    addOrderRow,
    updateOrderRow,
    getOrderRowById,
    deleteOrderRowByServiceId,
  }}>{children}</OrderContext.Provider>;
};

export const useOrder = () => useContext(OrderContext);

export default OrderContext;
