/* eslint-disable @typescript-eslint/no-explicit-any */
import { ShortUserRoleId } from './../@shared/schema/schema';
import { getMessaging, getToken } from 'firebase/messaging';
import type {
  FeatureId, ModuleId, PlanId, User, UserRoleId
} from '@shared/schema';
/* eslint-disable require-jsdoc */
import {
  AdvancedAuthState, AuthenticationPlatformAdapter,
  AuthenticationType, FinalizeFunction
} from '@mindhiveoy/react-auth';
import { AuthError } from '@mindhiveoy/auth';
import {
  AuthErrorCodes, FacebookAuthProvider, GoogleAuthProvider, getAuth,
  getRedirectResult, onAuthStateChanged, onIdTokenChanged, signInAnonymously, signInWithEmailAndPassword, signInWithRedirect, signOut
} from 'firebase/auth';
import { UserDocumentReference } from 'schema/users';
import { collection, doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { firebaseApp } from 'schema';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { isBrowser } from '@mindhiveoy/foundation';
import config from 'config';
import type {
  Email, PhoneNumber, PostalCode, StreetAddress, UserDisplayName, UserToken
} from '@mindhiveoy/schema';
import type { AuthError as FirebaseAuthError } from 'firebase/auth';

const app = firebaseApp();
const functions = getFunctions(app);

type AuthState = AdvancedAuthState<PlanId, ModuleId, FeatureId, ShortUserRoleId, User<ShortUserRoleId>>;

// TODO Move to mind-cloud
class FirebaseAuthPlatformAdapter implements AuthenticationPlatformAdapter<PlanId, ModuleId, FeatureId, ShortUserRoleId, User<ShortUserRoleId>> {
  private _signUpUserWithEmail = httpsCallable<any, any>(
    functions, 'signUpUserWithEmail'
  );

  private _registerNotificationToken = httpsCallable<any, any>(
    functions, 'registerNotificationToken'
  );

  public readonly id: 'firebase';

  public readonly supportedAuthTypes: [
    AuthenticationType.EMAIL,
    AuthenticationType.GOOGLE,
  ];

  private _unsubscribeUser?: () => void;

  public onInitialize = (
    context: AuthState):
    FinalizeFunction<any, ModuleId, any, ShortUserRoleId, any> => {
    const app = firebaseApp();
    const auth = getAuth(app);

    const unsubscribe = this._unsubscribeUser;

    const unsubscribeAuthChange = onAuthStateChanged(auth,
      // eslint-disable-next-line sonarjs/cognitive-complexity
      async (user) => {
        context.updateUserState(user);

        // TODO https://firebase.google.com/docs/auth/admin/custom-claims
        unsubscribe && unsubscribe();

        if (user) {
          // The token must be fetched before reading the user data from the Firestore
          if (isBrowser()) {
            try {
              const accessToken = await user.getIdToken(true);
              const result = await user.getIdTokenResult();

              const token = result.claims as unknown as UserToken<any, UserRoleId>;
              context.updateState({
                authenticationActivated: false,
                role: token.role,
                token,
                accessToken,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } as any);
            } catch (error) {
              console.error(error);
            }

            try {
              const messaging = getMessaging(app);

              const token = await getToken(messaging, {
                vapidKey: config.messaging.vapidKey,
              });

              await this._registerNotificationToken({
                token,
              });
            } catch (error) {
              console.error(error); // TODO Sentry
            }
          }

          const firestore = getFirestore(app);

          const usersRef = collection(firestore, 'users');
          const userRef = doc(usersRef, user.uid) as UserDocumentReference;

          this._unsubscribeUser = onSnapshot(userRef, async (snapshot) => {
            if (snapshot.exists()) {
              const _user = snapshot.data();
              const appUser: Partial<any> = {
                email: _user.email,
                photoURL: _user.photoURL,
                // role: _user.role,
                isAnonymous: !_user.email,
                emailVerified: _user.emailVerified,
                firstName: _user.firstName,
                lastName: _user.lastName,
                phoneNumber: _user.phoneNumber,
                streetAddress: _user.streetAddress,
                postalCode: _user.postalCode,
                city: _user.city,
              };

              // Token will be refreshed when ever user data will change. This will
              // verify that that claims will work on situations where member access roles
              // are being changed such as registration.
              const accessToken = await user.getIdToken(true);
              const result = await user.getIdTokenResult();

              const token = result.claims as unknown as UserToken<PlanId, UserRoleId>;
              // TODO: Typing
              (appUser as any).role = token.role;
              (appUser as any).token = token;
              (appUser as any).accessToken = accessToken;

              try {
                if (context.user?.email !== appUser.email || context.user?.photoURL !== appUser.photoURL ||
                  context.user?.emailVerified !== appUser.emailVerified || context.user?.firstName !== appUser.firstName ||
                  context.user?.lastName !== appUser.lastName || context.user?.phoneNumber !== appUser.phoneNumber ||
                  context?.user.streetAddress !== appUser.streetAddress || context?.user.postalCode !== appUser.postalCode ||
                  context?.user.city !== appUser.city) {
                  context.updateUserState(appUser);
                }
              } catch (error) {
                // TODO:: Sentry
                console.error(error);
              }
            }
          });
        } else {
          if (user === null) {
            signInAnonymously(auth);
          }
        }
      }, (error) => {
        console.log(error);
      }
    );

    context.updateUserState(context.user);

    const unsubscribeTokenChange = onIdTokenChanged(auth, async (user) => {
      if (!user) {
        return;
      }
      try {
        const accessToken = await user.getIdToken(true);

        const result = await user.getIdTokenResult();

        const token = result.claims as unknown as UserToken<any, UserRoleId>;
        context.updateState({
          token,
          accessToken,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any); // TODO fix typing
      } catch (error) {
        console.error(error);
      }
    });

    return () => {
      unsubscribeAuthChange && unsubscribeAuthChange();
      unsubscribeTokenChange && unsubscribeTokenChange();
      this._unsubscribeUser && this._unsubscribeUser();
    };
  };

  public refreshWebToken = async (context: AuthState) => {
    const auth = getAuth();

    const user = auth.currentUser;

    if (user) {
      const accessToken = await user.getIdToken(true);

      const result = await user.getIdTokenResult();

      const token = result.claims as unknown as UserToken<any, UserRoleId>;
      context.updateState({
        token,
        accessToken,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any); // TODO fix typing
    }
  };

  public signInWithEmail = async (context: AuthState, email: string, password: string) => {
    const auth = getAuth(firebaseApp());
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const appUser: Partial<any> = {
        uid: user.uid,
        displayName: user.displayName,
        email: user.email,
      };

      context.updateUserState(appUser);
    } catch (error) {
      raiseAsAuthError(error);
    }
  };

  public signInWIthCredentials = async (
    context: AuthState,
    authenticationType: AuthenticationType
  ) => {
    const auth = getAuth(firebaseApp());

    switch (authenticationType) {
      case 'google':
        try {
          const provider = new GoogleAuthProvider();
          provider.setCustomParameters({
            prompt: 'select_account',
          });

          await signInWithRedirect(auth, provider);
        } catch (error) {
          raiseAsAuthError(error);
        }
        break;

      case 'facebook':
        try {
          const provider = new FacebookAuthProvider();
          // provider.setCustomParameters({ prompt: 'select_account' });
          signInWithRedirect(auth, provider);
          await getRedirectResult(auth);

          // const user = result.user;
        } catch (error) {
          raiseAsAuthError(error);
        }
        break;

      default:
        throw new Error(`Unsupported authentication type: ${authenticationType}`);
    }
  };

  public signUpWithEmail = async (
    _context: AuthState,
    email: Email,
    password: string,
    displayName: UserDisplayName,
    firstName?: string,
    lastName?: string,
    phoneNumber?: PhoneNumber,
    streetAddress?: StreetAddress,
    postalCode?: PostalCode,
    city?: string
  ) => {
    try {
      await this._signUpUserWithEmail({
        email,
        password,
        displayName,
        firstName,
        lastName,
        phoneNumber,
        streetAddress,
        postalCode,
        city,
      });

      await this.signInWithEmail(_context, email, password);
    } catch (error) {
      raiseAsAuthError(error);
    }
  };

  public signUp = async () => {
    throw new Error('not implemented yet');
  };

  public signOut = async () => {
    const auth = getAuth(firebaseApp());
    await signOut(auth);
  };

  public resetPassword = async () => {
    throw new Error('not implemented yet');
  };
}

/**
 * Converts firebases authentication errors to mindcloud's auth error codes.
 * Please use the AuthErrorCodes found from Firebase package to make sure that no typos happen.
 * @param {FirebaseAuthError} error Object from firebase authentication error.
 */
const raiseAsAuthError = (error: FirebaseAuthError) => {
  switch (error.code) {
    case AuthErrorCodes.INVALID_PASSWORD:
    case AuthErrorCodes.INVALID_EMAIL:
      throw new AuthError(
        'auth_wrong_password_or_email',
        'Wrong email address or password'
      );
    case AuthErrorCodes.WEAK_PASSWORD:
      throw new AuthError(
        'auth_too_weak_password',
        'Too weak password'
      );
    case AuthErrorCodes.EMAIL_EXISTS:
      // TODO change the error code to correct one when pr is merged to mindcloud
      throw new AuthError('auth_internal_error', 'The email address is already in use by another account.');
    // TODO Review required error codes and make the mapping
    default:
      throw new AuthError(
        'auth_internal_error',
        error.message
      );
  }
};

export const firebaseAuthPlatformAdapter = new FirebaseAuthPlatformAdapter();
