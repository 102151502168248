import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { StepProps } from '../RegistrationProgressFlow';
import { useTranslation } from 'next-i18next';
import FormElement from 'components/forms/FormElement';
import theme from 'theme';
import useMediaQuery from '@mui/material/useMediaQuery';

const Step3 = ({
  nextOnClick,
  registerOnClick,
  form,
  events,
}: StepProps) => {
  const {
    t,
  } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return <>
    <Grid container rowGap={2} alignItems='center'>

      <Grid item xs={12} sm={8}>
        <Typography variant='h1'>
          {t('Personal information')}
        </Typography>
      </Grid>

      {/* Help text */}
      <Grid item xs={12} sm={8}>
        <Typography variant='body1'>
          {t('Fill your contact information and press Register Next we\'ll send a confirmation email to you')}.
        </Typography>
      </Grid>

      <Grid container rowGap={isMobile ? 2 : 4} marginTop={isMobile ? 3 : 5} columnGap={2} marginBottom={4}>

        <Grid container rowGap={isMobile ? 2 : 0} columnGap={2}>
          <Grid container rowGap={1} xs={12} sm={5}>
            <Grid item xs={12}>
              {t('First Name')}
            </Grid>
            <Grid item xs={12}>
              <FormElement field="firstName">
                <TextField
                  value={form.values.firstName}
                  id="firstName"
                  {...events}
                />
              </FormElement>
            </Grid>
          </Grid>
          <Grid container rowGap={1} xs={12} sm={5}>
            <Grid item xs={12}>
              {t('Last Name')}
            </Grid>
            <Grid item xs={12}>
              <FormElement field="lastName">
                <TextField
                  value={form.values.lastName}
                  id="lastName"
                  {...events}
                />
              </FormElement>
            </Grid>
          </Grid>
        </Grid>

        <Grid container rowGap={isMobile ? 2 : 0} columnGap={2}>
          <Grid container rowGap={1} xs={12} sm={5}>
            <Grid item xs={12}>
              {t('Street Address')}
            </Grid>
            <Grid item xs={12}>
              <FormElement field="streetAddress">
                <TextField
                  value={form.values.streetAddress}
                  id="streetAddress"
                  {...events}
                />
              </FormElement>
            </Grid>
          </Grid>
          <Grid container rowGap={1} xs={12} sm={5}>
            <Grid item xs={12}>
              {t('Postal Code')}
            </Grid>
            <Grid item xs={12}>
              <FormElement field="postalCode">
                <TextField
                  value={form.values.postalCode}
                  id="postalCode"
                  {...events}
                />
              </FormElement>
            </Grid>
          </Grid>
        </Grid>

        <Grid container rowGap={isMobile ? 2 : 0} columnGap={2}>
          <Grid container rowGap={1} xs={12} sm={5}>
            <Grid item xs={12}>
              {t('City')}
            </Grid>
            <Grid item xs={12}>
              <FormElement field="city">
                <TextField
                  value={form.values.city}
                  id="city"
                  {...events}
                />
              </FormElement>
            </Grid>
          </Grid>
          <Grid container rowGap={1} xs={12} sm={5}>
            <Grid item xs={12}>
              {t('Phone Number')}
            </Grid>
            <Grid item xs={12}>
              <FormElement field="phoneNumber">
                <TextField
                  value={form.values.phoneNumber}
                  id="phoneNumber"
                  {...events}
                />
              </FormElement>
            </Grid>
          </Grid>
        </Grid>

      </Grid>

      <Grid item xs={12} sm={4}>
        <Button variant='contained' color='secondary' onClick={nextOnClick(3)} disabled={!form.isFormValid}>
          {t('Sign up')}
        </Button>
      </Grid>

      <Grid item xs={12} sm={12}>
        <Button variant='text' color='secondary'>
          <span className='button-text-underline' onClick={nextOnClick(1)}>{t('Back')}</span>
        </Button>
      </Grid>

    </Grid>
  </>;
};
export default Step3;
