interface SendParamsRecipient {
  Email: string;
  Name?: string;
}
export interface EmailData {
  to: SendParamsRecipient[];
  status: EmailStatus;
  htmlPart?: string;
  textPart?: string;
  fromUser?: string;
  subject?: string;
  error?: Error;
  attachments?: Attachment[];
}

export enum EmailStatus {
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

interface Attachment {
  filename: string,
  path: string,
  cid: string
  }
