/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import { Show as BaseShow, ShowProps } from '@mindhiveoy/react-auth';

// export const Show = BaseShow as ({
//   children,
//   forOwner,
//   orRender,
//   whenAuthenticated,
//   withFeature,
//   withRole,
//   withRoles,
// }: React.PropsWithChildren<ShowProps<UserRoleId, FeatureId>>) => React.ReactElement<any, any> | null;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { PropsWithChildren } from 'react';

import { AuthContext, WithRenderWhenDenied, useAuth } from '@mindhiveoy/react-auth';
import { Permissions, expandShortRoleId, hasAnyFeatures, hasRole } from '@mindhiveoy/auth';

export interface ShowProps<UserRoleId, FeatureId> extends WithRenderWhenDenied {
  /**
   * When true, the child elements will be rendered only when user has been authenticated.
   */
  whenAuthenticated?: boolean;

  /**
   * When true, child elements will be rendered only when the user has been identified. This will
   * filter anonymous users away.
   */
  whenIdentifiedUser?: boolean;

  /**
   * When true, the child elements will be rendered only when the user is authenticated and his email is verified.
   */
  whenEmailVerified?: boolean;

  /**
   * Show the block for users which has a feature or a set of features with some access
   * level. This will set the permissions for the context, so that you can filter the
   * the visibility more with Can -element.
   */
  withFeature?: FeatureId | FeatureId[];

  /**
   * Define an access with different permissions for different roles
   */
  withPermissions? : Permissions<UserRoleId>;

  /**
   * Show block for defined roles with no permissions defined. This is a shortcut
   * for simple cases where no permissions are not needed to be defined.
   */
  withRole?: UserRoleId;

  withRoles?: UserRoleId[];

  /**
   * Show content for the owner
   */
  forOwner?: boolean; // TODO The use of owner as a role is a bit ambiguous currently. Needs some clarity.
}

// TODO: Change to use Mindcloud's real Show -component

export const Show = <UserRoleId extends string, FeatureId extends string>({
  whenAuthenticated,
  whenIdentifiedUser,
  whenEmailVerified,
  withFeature,
  children,
  withRole,
  withRoles,
  forOwner,
  orRender = null,
}: PropsWithChildren<ShowProps<UserRoleId, FeatureId>>) => {
  const auth = useAuth<any, any, FeatureId, UserRoleId, any>();

  const {
    authenticated,
    runtimePlan,
    role,
    isOwner,
    acquireAuthentication,
  } = auth;

  if ((whenAuthenticated || whenIdentifiedUser || whenEmailVerified) && !authenticated) {
    acquireAuthentication();
    return orRender;
  }

  if (whenIdentifiedUser && auth.user?.isAnonymous) {
    return orRender;
  }

  if (whenEmailVerified && !auth.user?.emailVerified) {
    return orRender;
  }

  if (forOwner && !isOwner) {
    if (!authenticated) {
      acquireAuthentication();
    }
    return orRender;
  }

  if (withFeature) {
    if (!authenticated) {
      acquireAuthentication();
      return orRender;
    }

    if (!role || !runtimePlan || !hasAnyFeatures(withFeature, runtimePlan, role)) {
      return orRender;
    }
  }

  if (withRole || withRoles) {
    if (withRole && withRoles) {
      throw new Error('The definition is ambiguous as withRole and withRoles are both defined.');
    }
    if (!authenticated) {
      acquireAuthentication();
      return orRender;
    }
    if (!role || !hasRole<any>(expandShortRoleId(role),
      withRole ?? withRoles)) {
      return orRender;
    }
  }

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
};
