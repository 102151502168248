import { PhoneNumber, SpaceId } from '@mindhiveoy/schema';

export enum EmailVerificationStatus {
  /**
   * Open you can use this with correct id
   */
  OPEN = 'open',
  /**
   * Closed by some reason. Reason should be in error object
   */
  CLOSED = 'closed',
  /**
   * Verification is used
   */
  USED = 'used',
  /**
   * Error, error message should be in error object
   */
  ERROR = 'error'
}

export interface EmailVerification {
  name: string;
  email: string;
  userId: string;
  /**
   * Email verication expires after this time
   */
  expires?: number;
  /**
   * Defines which organization the user was registering
   * with this information the return url can be defined correctly
   */
  spaceId: string;

  /**
   * Id of email that is sended to user
   */
  emailId?: string;
  status?: EmailVerificationStatus;
  created?: number;
  error?: Error;
}

export interface UserRegisterRequest {
  name: string;
  email: string;
  space: SpaceId;
  phoneNumber: PhoneNumber;
}

export interface SendEmailVerificationLinkRequest {
  email: string;
  spaceId: SpaceId;
}

export interface ValidateEmailRequest {
  /**
   * ID of email verification
   */
  id: string;
}
