/* eslint-disable @typescript-eslint/no-empty-function */
import { ExtraService, Service } from '@shared/schema/services';
import { clone } from 'lodash';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import { firebaseApp } from 'schema';
import { rrulestr } from 'rrule';
import React, {
  createContext, useCallback, useContext, useEffect, useState
} from 'react';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

dayjs.extend(isoWeek);

export const DEFAULT_MINIMUM_PRICE = 12000;
export const DEFAULT_CATEGORY_NAME = 'Kategoria';
export interface AppServiceContext {
  /**
   * List of all services from Firestore
   */
  services: Service[];
  /**
   * List of the 4 latest services (needed in some places in the UI)
   */
  latestServices: Service[];
  /**
   * Currently selected service
   */
  selectedService: Service | undefined;
  /**
   * Current order row id for manipulations with the current order
   * TODO: refactor this later, this should be logically in a different place
   */
  currentOrderRowId: string | undefined;
  /**
   * Extra services that the user selected for his order, needed just for the UI to render properly
   * TODO: same as with currentOrderRowId
   */
  selectedExtraServices: ExtraService[];
  /**
   * Boolean value to indicate whether there is a loading operation ongoing
   */
  areServicesLoading: boolean;
  setSelectedService: (value: Service) => void;
  /**
   * Get a service object by service id
   */
  getServiceById: (id: string) => Service | undefined;
  /**
   * Get an array of latest services, but first exclude the service with the specified id
   */
  getLatestServicesWithoutGivenService: (id: string) => Service[];
  /**
   * Get a minimum price for the given service id (or DEFAULT_MINIMUM_PRICE as a fallback)
   */
  getServiceMinimumPrice: (id: string) => number;
  setCurrentOrderRowId: (value: string) => void;
  /**
   * Setter for the services selected by the user
   */
  setSelectedExtraServices: (value: ExtraService[]) => void;
  /**
   * Function to remove an extra service from the array of selected services
   */
  removeExtraService: (id: string) => void;
  /**
   * Get the human readable comma-separated list of categories a service belongs to
   */
  getHumanReadableServiceCategory: (service: Service) => string;
  /**
   * Get the number of the first week where there should be available time,
   * taking time gap into account, BUT not taking booked slots into account (TODO: move this logic to cloud function)
   */
  getFirstAvailableWeek: (service: Service) => dayjs.Dayjs;
}

export const defaultServiceState: AppServiceContext = {
  services: [],
  latestServices: [],
  selectedService: undefined,
  currentOrderRowId: undefined,
  selectedExtraServices: [],
  areServicesLoading: true,
  setSelectedService: () => { },
  getServiceById: () => undefined,
  getLatestServicesWithoutGivenService: () => [],
  getServiceMinimumPrice: () => DEFAULT_MINIMUM_PRICE,
  setCurrentOrderRowId: () => { },
  setSelectedExtraServices: () => { },
  removeExtraService: () => { },
  getHumanReadableServiceCategory: () => DEFAULT_CATEGORY_NAME,
  getFirstAvailableWeek: () => dayjs(),
};

const ServiceContext = createContext<AppServiceContext>(defaultServiceState);

interface ServiceProviderTypes {
  state: AppServiceContext;
  children: JSX.Element | JSX.Element[];
}

export const ServiceProvider = ({
  state, children,
}: ServiceProviderTypes) => {
  const [selectedService, setSelectedServiceState,] = useState<Service | undefined>(state.selectedService);
  const [currentOrderRowId, setCurrentOrderRowId,] = useState<string | undefined>(state.currentOrderRowId);
  const [selectedExtraServices, setSelectedExtraServices,] = useState<ExtraService[]>(state.selectedExtraServices);
  const [services, setServices,] = useState<Service[]>(state.services);
  const [latestServices, setLatestServices,] = useState<Service[]>(state.latestServices);
  const [areServicesLoading, setAreServicesLoading,] = useState<boolean>(state.areServicesLoading);
  const [servicesMinimumPrices, setServicesMinimumPrices,] = useState(new Map<string, number>());

  const firestore = getFirestore(firebaseApp());

  /**
   * Initially load the services from firestore to provide those later when needed
   * without extra requests to Firestore (faster UX + potentially less money spent)
   */
  useEffect(() => {
    setAreServicesLoading(true);
    const getServices = async () => {
      const servicesRef = collection(firestore, 'services');
      const servicesSnapshot = await getDocs(servicesRef);

      const allServices = servicesSnapshot.docs.map((doc) => {
        return {
          ...doc.data(),
          id: doc.id,
        } as Service;
      });
      const hiddenServices = allServices.filter((service) => service.visible === false);
      const visibleServices = allServices.filter((service) => service.visible);
      const newServices = [...hiddenServices, ...visibleServices,];
      newServices.reverse().sort((value) => {
        return value.pricingModel.isDiscountPrice ? -1 : 1; // `true` values (i.e. with discount) first
      });
      setServices(newServices);
      setLatestServices(newServices.slice(0, 4));
      setAreServicesLoading(false);
    };
    getServices();
  }, [firestore,]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    const newServicesMinimumPrices = clone(servicesMinimumPrices);
    newServicesMinimumPrices.clear();
    services.forEach((service) => {
      if (service.type !== 'laundry' && service.type !== 'repair' && service.type !== 'extra' && service.pricingModel.categories.length > 0) {
        const categories = service.pricingModel.categories;
        // find minimum non-zero price
        let minPrice = categories[0]?.totalPrice > 0 ? categories[0].totalPrice : undefined;
        if (categories.length > 1) {
          for (let i = 1; i < categories.length; i++) {
            if (categories[i].totalPrice <= 0) continue; // skip zero prices
            if (!minPrice || !!minPrice && categories[i].totalPrice < minPrice) {
              minPrice = categories[i].totalPrice;
            }
          }
        }
        if (!minPrice) minPrice = 0;
        newServicesMinimumPrices.set(service.id, minPrice);
      } else if (service.type === 'repair' || service.type === 'laundry') {
        const categories = service.pricingModel.categories;
        let minPrice = categories[0].pricePerUnit;
        if (categories.length > 1) {
          for (let i = 1; i < categories.length; i++) {
            if (categories[i].unit !== 'm2' && categories[i].pricePerUnit < minPrice) minPrice = categories[i].pricePerUnit;
          }
        }
        if (service.type === 'laundry') {
          // also add delivery price for laundry
          const deliveryPrice = service.deliveryCategory?.totalPrice ?? 1600;
          minPrice += deliveryPrice;
        }
        newServicesMinimumPrices.set(service.id, minPrice);
      } else {
        newServicesMinimumPrices.set(service.id, DEFAULT_MINIMUM_PRICE);
      }
    });
    setServicesMinimumPrices(newServicesMinimumPrices);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services,]);

  const getServiceMinimumPrice = useCallback((serviceId: string) => {
    return servicesMinimumPrices.get(serviceId) ?? DEFAULT_MINIMUM_PRICE;
  }, [servicesMinimumPrices,]);

  const setSelectedService = useCallback((service: Service) => {
    setSelectedServiceState(service);
    setSelectedExtraServices([]); // reset extra services (for the UI)
  }, []);

  const getServiceById = useCallback((serviceId: string) => {
    return services.find((item) => item.id === serviceId);
  }, [services,]);

  const getLatestServicesWithoutGivenService = useCallback((serviceId: string) => {
    const filteredServices = services.filter((service) => service.id !== serviceId);
    return filteredServices.slice(0, 4);
  }, [services,]);

  const getHumanReadableServiceCategory = useCallback((service: Service) => {
    if (service.category) {
      return service.category.join(', ');
    } else {
      return DEFAULT_CATEGORY_NAME;
    }
  }, []);

  const getFirstAvailableWeek = useCallback((service: Service) => {
    const timeBudgets = service.timeBudget;
    let minWeek = dayjs().isoWeek();
    timeBudgets && timeBudgets.forEach((timeBudget) => {
      const minStart = dayjs().add(timeBudget.timeGap ?? 1, 'days');
      const rule = rrulestr(timeBudget.recurrenceRule);
      const firstDate = rule.after(minStart.toDate());
      if (firstDate && dayjs(firstDate).isoWeek() > minWeek) {
        minWeek = dayjs(firstDate).isoWeek();
      }
    });
    return dayjs().isoWeek(minWeek).startOf('isoWeek').locale('fi');
  }, []);

  /**
   * remove extra service selection from the UI
   */
  const removeExtraService = useCallback((id: string) => {
    const newExtraServices = selectedExtraServices.filter((extraService) => extraService.id !== id);
    setSelectedExtraServices(newExtraServices);
  }, [selectedExtraServices,]);

  return <ServiceContext.Provider value={{
    services,
    latestServices,
    selectedService,
    currentOrderRowId,
    selectedExtraServices,
    areServicesLoading,
    setSelectedService,
    getServiceById,
    getLatestServicesWithoutGivenService,
    getServiceMinimumPrice,
    setCurrentOrderRowId,
    setSelectedExtraServices,
    removeExtraService,
    getHumanReadableServiceCategory,
    getFirstAvailableWeek,
  }}>{children}</ServiceContext.Provider>;
};

export const useServices = () => useContext(ServiceContext);

export default ServiceContext;
