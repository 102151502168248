/* eslint-disable @typescript-eslint/no-explicit-any */

import { FormContext } from './Form';
import FieldMessage from './messages/FieldMessage';
import FormControl from '@mui/material/FormControl';
import React, { PropsWithChildren, useContext } from 'react';
import styled from '@emotion/styled';

const StyledFormControl = styled(FormControl)<{
  horizontal: boolean
  withtopmargin?: string
  withbottommargin?: string
}>(({
  theme,
  horizontal,
  withtopmargin,
  withbottommargin,
}) =>`
  width: 100%;
  margin-top: ${withtopmargin ? theme.spacing(2) : 0};
  margin-bottom: ${withbottommargin ? theme.spacing(2) : 0};
  ${horizontal && `
    display: flex;
    flex-direction: row;
    align-items: center;
  `
}
  &:last-child {
    margin-bottom: ${theme.spacing(0)};
  }
`);

export interface FormElementProps<T> {
  field?: keyof T,
  horizontal?: boolean;
  withTopMargin?: boolean;
  withBottomMargin?: boolean;
}
const FormElement = <T,>({
  children,
  field,
  horizontal,
  withTopMargin,
  withBottomMargin,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: PropsWithChildren<FormElementProps<T>>): JSX.Element => {
  const {
    messages,
  } = useContext(FormContext);

  return <StyledFormControl
    horizontal={horizontal}
    withtopmargin={withTopMargin ? 'true' : undefined}
    withbottommargin={withBottomMargin ? 'true' : undefined} >
    { children }
    {field && <FieldMessage<T>
      field={field}
      messages={messages}
    />}
  </StyledFormControl>;
};

export default FormElement;
