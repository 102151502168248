export enum VismaPayPaymentMethod {
  /**
   * Used for banking payments and the card form in the pay page
   */
  E_PAYMENT = 'e-payment',

  /**
   * Used for the inline card form
   */
  EMBEDDED = 'embedded',

  /**
   * Used for terminal payments
   */
  TERMINAL = 'terminal',
}

export enum PaymenObjectLanguage {
  FI = 'fi',
  EN = 'en',
  SV = 'sv',
  RU = 'ru'
}

export enum EPaymentTypes {
  /**
   * Enables all accessible bank payments
   */
  BANKS = 'banks',

  /**
   * Enables all accessible card payments
   */
  CREDIT_CARDS = 'creditcards',

  /**
   * Enables all accessible credit invoice payments
   */
  CREDITIN_INVOICES = 'creditinvoices',

  /**
   * Enables all accessible wallet payments
   */
  WALLETS = 'wallets',

  /**
   * Nordea
   */
  NORDEA = 'nordea',

  /**
   * HandelsBanken
   */
  HANDELSBANKEN = 'handelsbanken',

  /**
   * Osuuspankki
   */
  OSUUSPANKKI = 'osuuspankki',

  /**
   * DanskeBank
   */
  DANSKEBANK = 'danskebank',

  /**
   * Spankki
   */
  SPANKKI = 'spankki',

  /**
   * Säästöpankki
   */
  SAASTOPANKKI = 'saastopankki',

  /**
   * Paikallisosuuspankki, POP-pankki
   */
  PAIKALLISOSUUSPANKKI = 'paikallisosuuspankki',

  /**
   * Aktio
   */
  AKTIA = 'aktia',

  /**
   * Alandsbanken
   */
  ALANDSBANKEN = 'alandsbanken',

  /**
   * Oma säästöpankki
   */
  OMASAASTOPANKKI = 'omasaastopankki',

  /**
   * Jousto allows payments between 20€ and 3000€
   */
  JOUSTORAHA = 'joustoraha',

  /**
   * Enterpay-yrityslasku
   */
  LASKU_YRITYKSELLE = 'laskuyritykselle',

  /**
   * MobilePay wallet service
   */
  MOBILE_PAY = 'mobilepay',

  /**
   * Masterpass wallet service
   */
  MASTERPASS = 'masterpass',

  /**
   * Pivo wallet service
   */
  PIVO = 'pivo',

  /**
   * Siirto service
   */
  SIIRTO = 'siirto',
}

/**
 * Result code when calling checkStatus... Visma Pay API calls
 * https://www.vismapay.com/docs/web_payments/?page=reference-node#check-status-with-order-number
 * 0  Payment was completed successfully.
 * 1  Payment failed. A customer did not successfully finish the payment.
 * 4  Transaction status could not be updated after customer returned from a payment
 *    facilitator's web page. Please use the merchant UI to resolve the payment status.
 * 10 Maintenance break. The transaction is not created and the user has been notified
 *    and transferred back to the return address.
 */
export enum CheckResultCode {
  'SUCCESS' = 0,
  'PAYMENT_FAILED' = 1,
  'TRANSACTION_STATUS_COULD_NOT_BE_UPDATED' = 4,
  'MAINTENANCE_BREAK' = 10,
}

export enum PaymentSettled {
  /**
   * authorized / not settled
   */
  'AUTHORIZED_OR_NOT_SETTLED' = 0,
  'SETTLED' = 1,
}

/**
 * ChargeResultCode
 * https://www.vismapay.com/docs/web_payments/?page=reference-node#create-charge
 */
export enum ChargeResultCode {
  'SUCCESS' = 0,
  'VALIDATION_ERROR' = 1,
  'DUPLICATE_ORDER_NUMBER' = 2,
  'MAINTENANCE_BREAK' = 10,
}
