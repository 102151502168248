
/* eslint-disable @typescript-eslint/no-explicit-any */

import { PropsWithChildren } from 'react';
import { motion } from 'framer-motion';
import MuiAlert from '@mui/material/Alert';
import styled from '@emotion/styled';

const StyledAlert = styled(MuiAlert)(({
  theme,
}) => `
  justify-content: center;
  letter-spacing: 0.4px;
  width: 100%;
  margin-bottom: ${theme.spacing(2)};
  background-color: ${theme.palette.background.default};
  & * {
    color:${theme.palette.error.main};
  }
`);

interface AlertProps {
  visible: boolean
}

const Alert = ({
  children, visible = false,
}: PropsWithChildren<AlertProps>) => {
  return <motion.div key="alert" animate={{
    opacity: visible ? 1 : 0,
  }}><StyledAlert>{children}</StyledAlert></motion.div>;
};

export default Alert;
